import { Component, Input} from '@angular/core';
import { NbDialogRef, NbDialogService, NbThemeService } from '@nebular/theme';
import { ApiService } from './../../../../shared/api.service';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { config } from './../../../../../../config';
import {ConfirmationService} from 'primeng/api';
import { previewImg } from './transcriptpreview.component';
@Component({
selector: 'nb-dialog',
template: `
<nb-card *ngIf="component_no==1" [nbSpinner]="loading" nbSpinnerStatus="danger" nbSpinnerSize="xlarge">
  <nb-card-header>Add Reason</nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-xl-12" style='center;'>
        <b>Input the name of college where you are seeking admission :</b>
        <html>
        <head>
        <meta charset ="UTF-8" />
        </head>
        <body>
          <b style="color:red;">તમે જે યુનિવર્સિટીમાં પ્રવેશ મેળવવા માગો છો તેનું નામ દાખલ કરો</b>
        <input type="text" class="form-control" (input)="onSearchChange($event.target.value)" [(ngModel)]="reason" name='inputTranscriptName' id="inputTranscriptName" placeholder="Reason">
        <span style="color:red;" *ngIf="errorMessage == true">Kindly input name of University you are seeking admission:</span>
          </body>
          </html>
      </div><br><br><br>
      <div class="col-xl-12">
      <br>
      <p style="color:red; font-weight: bold;" >Notes:</p>
      <span>Kindly ensure your Transfer Certificate has the date mentioned on it (Should be within 6 months from the Current date).<br> Also note it should be stamped & signed by the college authorities.<br> In case it is as mentioned then you can click to proceed with your application. <br>In case either date is not mentioned OR its not stamped & signed,<br> then click CANCEL & contact your college to update DATE, STAMP & SIGN on the certificate</span>
      <br>
      <br>
    <!--  <p style="font-weight: bold;">Preview Of Transfer Certificate: </p>
      <img src="{{serverUrl}}/upload/sample/tc_formate.jpg" nbPopover="Click here to download the document" nbPopoverMode="hover" style="height:117px; width:124px;" (click)="onView(Transfer?.sample_filename,Transfer?.sample_type)"> -->
      <br><br><span style="margin: 2px 11px 1px 1px; font-size: 15px; font-weight: bold;">I have read and agree to the <span style="color:blue;">Notes</span></span><nb-checkbox  (click)="accept($event)" [value]="checked" ></nb-checkbox>
        <span *ngIf='uploaderror == true' style="color:red; font-size: large;">Max uploaded file size should be 5 MB.</span>
      </div>
      <div class="col-xl-12" *ngIf="showUpload == true && showUpload1 == true">
        <p-fileUpload ngDefaultControl [accept]="'.jpg,.jpeg,.png,.pdf,.PNG,.JPEG,.JPG,.PDF'" mode="basic" auto="true"
          chooseLabel="Upload" name="file" url="{{transUploadUrl}}?user_id={{user_id}}&&transcript_name={{marksheet}}&&hiddentype=User_Marsheet&&reason={{reason}}"
          maxFileSize="5000000"
          (onBeforeSend)="onBeforeSend($event)"
          (onUpload)="onUpload($event)"
          (onSelect)="onSelect($event)"
          (onError)="onErrorFileUpload($event)">
        </p-fileUpload>
        <span *ngIf='uploaderror == true' style="color:red; font-size: x-large;">Max uploaded file size should be 5 MB.</span>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton hero status="primary" (click)="dismiss()">Close</button>
  </nb-card-footer>
</nb-card>
<nb-card *ngIf="component_no==0" [nbSpinner]="loading" nbSpinnerStatus="danger" nbSpinnerSize="xlarge">
<nb-card-header>Preview Of Marksheet</nb-card-header>
<nb-card-body>
  <p style="font-weight: bold;">Preview Of Marksheet:<br>
  Marksheet refers to the statement of marks issued by the authority/board depicting a student’s marks obtained/achieved. It also contains other details like the name, seat number etc. </p>
  <div class="row">
      <div class="col-md-6">
          <h4 style="color: red;"> Right Format</h4>
          <img class="headerimages" src="https://mu.migrationcertificate.in/uiserver/upload/profile_pic/right.png"/>
          <img class="viewMoreImg" src="{{Marksheet2?.sample_filename}}"  nbPopover="Click here to view the document" nbPopoverMode="hover" (click)="onView(Marksheet2?.sample_filename,Marksheet2?.sample_type)"/> 
      </div>
      <div class="col-md-6">
          <h4 style="color: red;"> Wrong Format</h4>
         <img class="headerimages" src="https://mu.migrationcertificate.in/uiserver/upload/profile_pic/cross.jpg"/>
          <img class="viewMoreImg" src="{{Marksheet1?.sample_filename}}"  nbPopover="Click here to view the document" nbPopoverMode="hover" (click)="onView(Marksheet1?.sample_filename,Marksheet1?.sample_type)"/> 
      </div>
  </div>
  <br>
  <span style="margin: 2px 11px 1px 1px; font-size: 15px; font-weight: bold;">I have read and agree to the <span style="color:blue;">Notes</span></span><nb-checkbox  (click)="acceptMarksheet($event)" [value]="checked" ></nb-checkbox>
  <span *ngIf='uploaderror == true' style="color:red; font-size: large;">Max uploaded file size should be 5 MB.</span>
  </nb-card-body>
<nb-card-footer>
  <button nbButton hero  style="margin: 0px 19px 21px 4px;" status="primary" (click)="dismiss()">Close</button>
<p-fileUpload  *ngIf="showUpload1 == true" class="file_upload" ngDefaultControl [accept]="'.jpg,.jpeg,.png,.pdf,.PNG,.JPEG,.JPG,.PDF'" mode="basic" auto="true"
chooseLabel="Upload" name="file" url="{{transUploadUrl}}?user_id={{user_id}}&&transcript_name={{marksheet}}&&hiddentype=User_Marsheet"
maxFileSize="5000000"
(onBeforeSend)="onBeforeSend($event)"
(onUpload)="onUpload($event)"
(onSelect)="onSelect($event)"
(onError)="onErrorFileUpload($event)">
</p-fileUpload>
<span *ngIf='uploaderror == true' style="color:red; font-size: x-large;">Max uploaded file size should be 5 MB.</span>
</nb-card-footer>
</nb-card>

`,
providers:[ConfirmationService],
})
export class TranscriptDialogComponent {
@Input() title: string;
@Input() user_id: any;
@Input() component_no: any;
@Input() marksheet: any;
cbse_marks;
currenttoken;
showUpload;
loading = false;
user = { name : "", id:""};
reason;
serverUrl = config.serverUrl;
uploaderror = false;
uploaderror1 = false;
transUploadUrl = config.docUploadUrl;
  showUpload1: boolean;
  Marksheet2:any;
  Marksheet1: any;
  Transfer: any;
  PRN_Status_Report: any;
  Enrollment_Letter_sample: any;
  checked_value: any;
  errorMessage: boolean;


constructor(
  protected ref: NbDialogRef<TranscriptDialogComponent>,
  protected api : ApiService,
  private authService: NbAuthService,
  public themeService : NbThemeService,
  private dialogService : NbDialogService,
  private confirmationService: ConfirmationService,
  ) 
  {
    
  }

  dismiss() {
  this.ref.close();
  }

  onBeforeSend(event) {
    this.loading = true;
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
       this.currenttoken = token;
        event.xhr.setRequestHeader("Authorization", `Bearer ` +this.currenttoken);
        event.formData.append('token',''+this.currenttoken);
        }
    });
  }
  
  onUpload(event) {
      const reader = new FileReader();
      if(event.files && event.files.length) {
        const [file] = event.files;
        reader.readAsDataURL(file);
        this.loading = false;
        this.ref.close(file);
      }
  
  }
  
  acceptMarksheet($event){
    if($event.target.checked ==true){
      this.showUpload1=true;
    }else{
      this.showUpload1=false;
    }
  }
  
  selectedFile: File;

  onSelect($event: any): void {
		var maxFileSize =  5000000;
		var imgArr = $event.files[0].name.split('.');
    var extension = imgArr[imgArr.length - 1].trim();
    
    if(extension!='jpg' && extension!='jpeg' && extension!='png' && extension!='pdf'){
			this.uploaderror1 = true;
    }
    
    if ($event.files[0].size > maxFileSize) {
      this.uploaderror = true;
      this.confirmationService.confirm({
				message: 'Maximum file size should be 5 MB.',
				header: 'Invalid File Size',
				icon: 'pi pi-info-circle',
				rejectVisible : false,
				acceptLabel: 'Ok'
			});
    }

    
  }
  onErrorFileUpload(event){
  console.log(event);
  }


  ngOnInit() {
     this.api.getTheme().subscribe((data: any) => {
        if(data['data']){
          this.themeService.changeTheme(data['data']);
        }else{
          this.themeService.changeTheme('default');
        }
      });
      this.api.getApplicantFiles(this.user_id).subscribe(data =>{
        this.PRN_Status_Report = data['data']['PRN_Status_Report'];
        this.Transfer = data['data']['Transfer'];
        this.Enrollment_Letter_sample = data['data']['Enrollment_Letter_sample'];
        this.Marksheet1=data['data']['Marksheet1'];
        this.Marksheet2=data['data']['Marksheet2']; 
       ;
      })
   }

   onView(imagename,imgType){
    // console.log("imgType== "+imgType)
     this.dialogService.open(previewImg, {
       context: {
       arr : imagename,
       imgType : imgType
       },
     });
   }

   onSearchChange(searchValue : string ) {
    this.reason = searchValue
    if(searchValue.length > 3){
      this.showUpload1 = true;
      this.errorMessage=false;
    }else{
      this.showUpload1 = false;
    }
    
  }

  accept($event){
    this.checked_value = $event.target.checked;
    this.showUpload = true;
    if(this.reason){
      var text = this.reason;
      var result = text.trim();
      this.errorMessage = false;
      // if(result == ''){
      //   this.errorMessage = true;
      //   this.checked_value == false;
      // }else{
      //   if($event.target.checked ==true){
      //     this.showUpload=true;
      //   }else{
      //     this.showUpload=false;
      //   }
      // }
    }else{
      this.errorMessage = true;
    }
  }
  
}
