import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from './../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { FormBuilder, FormGroup , Validators} from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { UserService } from './../../@core/data/users.service';



@Component({
  selector: 'educational',
  template: `
  <style>
  .mat-form-field--inline {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .mat-form-field--inline .mat-form-field {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
    }

    .mat-form-field--inline .mat-form-field:nth-child(1) {
        margin-right: 10px;
    }
    
    @media (max-width: 767px) {
      #selectLine{  
        width: 65%;
      } 
    }
      
    @media (min-width: 1200px) {
      #selectLine{  
        width: 145%;
      } 
    }
      
    @media (min-width: 768px) {
      #selectLine{  
        width: 65%;
      } 
    }
      
    @media (min-width: 992px) {
      #selectLine{  
        width: 145%;
      } 
    }

    .example-full-width {
      
    }
  </style>
  <nb-card status="success"  [style.height.px]="630" [style.overflow]="'auto'">
    <nb-card-header>Add Educational Details</nb-card-header>
    <nb-card-body> 
      <span *ngIf="instructionValidation===false" style="color:red;">Please add medium of instruction which is below subjects.</span>
      <form [formGroup]="edu_form"  class="step-container">
        <div class="row">
          <div class="col-md-3">Course Name : </div>
          <div class="col-md-4">
            <mat-form-field >
              <mat-select ngModel="{{edu_data?.CourseName}}" name="coursename" formControlName="courseNameCtrl">
                  <mat-option value="{{course.CourseShortForm}}" *ngFor="let course of course_data">{{course.CourseName}}</mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="courseValidation===false" style="color:red;">Please select Course Name.</span>  
          </div>
          <div class="col-md-3" *ngIf="admin_show == 'true'">Total Hours : </div>
          <div class="col-md-2" *ngIf="admin_show == 'true'">
            <input  ngModel="{{edu_data?.total_weeks}}" nbInput type="text" formControlName="totalweeksCtrl" placeholder="" id="" class="form-control"> 
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">College Year : </div>
          <div class="col-md-2">
            <mat-form-field id="selectLine" >
              <mat-select ngModel="{{edu_data?.CollegeYear}}" name="collegeYear" formControlName="collegeYearCtrl">
                  <mat-option value="First">First</mat-option>
                  <mat-option value="Second">Second</mat-option>
                  <mat-option value="Third">Third</mat-option>
                  <mat-option value="Fourth">Fourth</mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="collegeYearValidation===false" style="color:red;">Please select College Year.</span> 
          </div>
          <div class="col-md-3">Semester : </div>
          <div class="col-md-2">
              <mat-form-field id="selectLine" >
                  <mat-select ngModel="{{edu_data?.Semester}}" name="semester" formControlName="semesterCtrl">
                      <mat-option value="I">I</mat-option>
                      <mat-option value="II">II</mat-option>
                      <mat-option value="III">III</mat-option>
                      <mat-option value="IV">IV</mat-option>
                      <mat-option value="V">V</mat-option>
                      <mat-option value="VI">VI</mat-option>
                      <mat-option value="VII">VII</mat-option>
                      <mat-option value="VIII">VIII</mat-option>
                  </mat-select>
              </mat-form-field>
              <span *ngIf="semesterValidation===false" style="color:red;">Please select semester</span> 
          </div>
      </div>
      <div class="row">
          <div class="col-md-3">Month : </div>
          <div class="col-md-2">
            <mat-form-field id="selectLine" >
              <mat-select  ngModel="{{edu_data?.Month}}" name="month" formControlName="monthCtrl">
                  <mat-option value="January">January</mat-option>
                  <mat-option value="February">February</mat-option>
                  <mat-option value="March">March</mat-option>
                  <mat-option value="April">April</mat-option>
                  <mat-option value="May">May</mat-option>
                  <mat-option value="June">June</mat-option>
                  <mat-option value="July">July</mat-option>
                  <mat-option value="August">August</mat-option>
                  <mat-option value="September">September</mat-option>
                  <mat-option value="October">October</mat-option>
                  <mat-option value="November">November</mat-option>
                  <mat-option value="December">December</mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="monthValidation===false" style="color:red;">Please select month</span> 
          </div>
          <div class="col-md-3">Year : </div>
          <div class="col-md-2">
              <mat-form-field id="selectLine" >
                  <mat-select ngModel="{{edu_data?.Year}}" name="year" formControlName="yearCtrl">
                      <mat-option value="{{years}}" *ngFor="let years of years_array">{{years}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <span *ngIf="yearValidation===false" style="color:red;">Please select year.</span> 
          </div>
      </div>
      <div class="row">
          <div class="col-md-3">Total obtained marks : </div>
          <div class="col-md-2">
            <input  ngModel="{{edu_data?.overall_marks_obt}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.overmarksobtCtrl.invalid && (edu_form.controls.overmarksobtCtrl.dirty || edu_form.controls.overmarksobtCtrl.touched)}" formControlName="overmarksobtCtrl" placeholder="" id="" class="form-control"> 
          </div>
          <div class="col-md-3">Out of : </div>
          <div class="col-md-2">
            <input  ngModel="{{edu_data?.overall_marks_out}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.overmarksoutCtrl.invalid && (edu_form.controls.overmarksoutCtrl.dirty || edu_form.controls.overmarksoutCtrl.touched)}" formControlName="overmarksoutCtrl" placeholder="" id="" class="form-control"> 
          </div>
      </div>
      <div class="row">
        <div class="col-md-2">Subject 1 :</div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.Subject_First}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.subjectFirstCtrl.invalid && (edu_form.controls.subjectFirstCtrl.dirty || edu_form.controls.subjectFirstCtrl.touched)}" formControlName="subjectFirstCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_First}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.markFirstCtrl.invalid && (edu_form.controls.markFirstCtrl.dirty || edu_form.controls.markFirstCtrl.touched)}" formControlName="markFirstCtrl" placeholder="Marks Obtained" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_First}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.markFirstOutCtrl.invalid && (edu_form.controls.markFirstOutCtrl.dirty || edu_form.controls.markFirstOutCtrl.touched)}" formControlName="markFirstOutCtrl" placeholder="Out of" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{edu_data?.lecture_hour_First}}" nbInput type="text" formControlName="lecturehourFirstCtrl" placeholder="Theory lectures" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{edu_data?.practical_hour_First}}" nbInput type="text" formControlName="practicalhourFirstCtrl" placeholder="Practical lectures" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-2">Subject 2 :</div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.Subject_Second}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.subjectSecondCtrl.invalid && (edu_form.controls.subjectSecondCtrl.dirty || edu_form.controls.subjectSecondCtrl.touched)}" formControlName="subjectSecondCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Second}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.markSecondCtrl.invalid && (edu_form.controls.markSecondCtrl.dirty || edu_form.controls.markSecondCtrl.touched)}" formControlName="markSecondCtrl" placeholder="Marks Obtained" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Second}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.markSecondOutCtrl.invalid && (edu_form.controls.markSecondOutCtrl.dirty || edu_form.controls.markSecondOutCtrl.touched)}" formControlName="markSecondOutCtrl" placeholder="Out of" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{edu_data?.lecture_hour_Second}}" nbInput type="text" formControlName="lecturehourSecondCtrl" placeholder="Theory lectures" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{edu_data?.practical_hour_Second}}" nbInput type="text" formControlName="practicalhourSecondCtrl" placeholder="Practical lectures" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-2">Subject 3 :</div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.Subject_Third}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.subjectThirdCtrl.invalid && (edu_form.controls.subjectThirdCtrl.dirty || edu_form.controls.subjectThirdCtrl.touched)}" formControlName="subjectThirdCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Third}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.markThirdCtrl.invalid && (edu_form.controls.markThirdCtrl.dirty || edu_form.controls.markThirdCtrl.touched)}" formControlName="markThirdCtrl" placeholder="Marks Obtained" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Third}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.markThirdOutCtrl.invalid && (edu_form.controls.markThirdOutCtrl.dirty || edu_form.controls.markThirdOutCtrl.touched)}" formControlName="markThirdOutCtrl" placeholder="Out of" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'" ngModel="{{edu_data?.lecture_hour_Third}}" nbInput type="text" formControlName="lecturehourThirdCtrl" placeholder="Theory lectures" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{edu_data?.practical_hour_Third}}" nbInput type="text" formControlName="practicalhourThirdCtrl" placeholder="Practical lectures" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-2">Subject 4 :</div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.Subject_Fourth}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.subjectFourthCtrl.invalid && (edu_form.controls.subjectFourthCtrl.dirty || edu_form.controls.subjectFourthCtrl.touched)}" formControlName="subjectFourthCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Fourth}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.markFourthCtrl.invalid && (edu_form.controls.markFourthCtrl.dirty || edu_form.controls.markFourthCtrl.touched)}" formControlName="markFourthCtrl" placeholder="Marks Obtained" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Fourth}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.markFourthOutCtrl.invalid && (edu_form.controls.markFourthOutCtrl.dirty || edu_form.controls.markFourthOutCtrl.touched)}" formControlName="markFourthOutCtrl" placeholder="Out of" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{edu_data?.lecture_hour_Fourth}}" nbInput type="text" formControlName="lecturehourFourthCtrl" placeholder="Theory lectures" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{edu_data?.practical_hour_Fourth}}" nbInput type="text" formControlName="practicalhourFourthCtrl" placeholder="Practical lectures" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-2">Subject 5 :</div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.Subject_Fifth}}" nbInput type="text"  formControlName="subjectFifthCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Fifth}}" nbInput type="text" formControlName="markFifthCtrl" placeholder="Marks Obtained" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Fifth}}" nbInput type="text" formControlName="markFifthOutCtrl" placeholder="Out of" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{edu_data?.lecture_hour_Fifth}}" nbInput type="text" formControlName="lecturehourFifthCtrl" placeholder="Theory lectures" id="" class="form-control"></div> 
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{edu_data?.practical_hour_Fifth}}" nbInput type="text" formControlName="practicalhourFifthCtrl" placeholder="Practical lectures" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-2">Subject 6 :</div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.Subject_Six}}" nbInput type="text"  formControlName="subjectSixthCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Six}}" nbInput type="text" formControlName="markSixthCtrl" placeholder="Marks Obtained" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Six}}" nbInput type="text" formControlName="markSixthOutCtrl" placeholder="Out of" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{edu_data?.lecture_hour_Six}}" nbInput type="text" formControlName="lecturehourSixCtrl" placeholder="Theory lectures" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{edu_data?.practical_hour_Six}}" nbInput type="text" formControlName="practicalhourSixCtrl" placeholder="Practical lectures" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-2">Subject 7 :</div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.Subject_Seventh}}" nbInput type="text"  formControlName="subjectSeventhCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Seventh}}" nbInput type="text" formControlName="markSeventhCtrl" placeholder="Marks Obtained" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Seventh}}" nbInput type="text" formControlName="markSeventhOutCtrl" placeholder="Out of" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{edu_data?.lecture_hour_Seventh}}" nbInput type="text" formControlName="lecturehourSeventhCtrl" placeholder="Theory lectures" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{edu_data?.practical_hour_Seventh}}" nbInput type="text" formControlName="practicalhourSeventhCtrl" placeholder="Practical lectures" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-2">Subject 8 :</div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.Subject_Eighth}}" nbInput type="text"  formControlName="subjectEighthCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Eighth}}" nbInput type="text" formControlName="markEighthCtrl" placeholder="Marks Obtained" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Eighth}}" nbInput type="text" formControlName="markEighthOutCtrl" placeholder="Out of" id="" class="form-control"></div>
        <div class="col-md-2"><input *ngIf="admin_show == 'true'" ngModel="{{edu_data?.lecture_hour_Eighth}}" nbInput type="text" formControlName="lecturehourEighthCtrl" placeholder="Theory lectures" id="" class="form-control"></div>
      <div class="col-md-2"><input *ngIf="admin_show == 'true'"  ngModel="{{edu_data?.practical_hour_Eighth}}" nbInput type="text" formControlName="practicalhourEighthCtrl" placeholder="Practical lectures" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-3">Medium of instruction : </div>
        <div class="col-md-3"> <input  ngModel="{{edu_data?.medium_instruction}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.instructionCtrl.invalid && (edu_form.controls.instructionCtrl.dirty || edu_form.controls.instructionCtrl.touched)}" formControlName="instructionCtrl" placeholder="" id="" class="form-control"> </div>
        <div class="col-md-3" *ngIf="admin_show == 'true'">Special Instruction : </div>
        <div class="col-md-3" *ngIf="admin_show == 'true'"><input  ngModel="{{edu_data?.special_instruction}}" nbInput type="text" formControlName="specialInstrctionCtrl" placeholder="" id="" class="form-control"> </div>          
      </div>
      </form>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton hero status="primary" (click)="dismiss()">Close</button> 
        <button *ngIf="amount_paid == 'false'" nbButton status="info" (click)="saveEdu()">save</button>
    </nb-card-footer>
</nb-card>
  `
})
export class EducationalFormBafComponent implements OnInit {
  FeedbackForm : FormGroup;
  @Input() user_id: any;
  @Input() edu_id: any;
  @Input() amount_paid:string;
  @Input() admin_show:string;
  validations_flag = false;
  feedback_message ;
  showExperience = false;

  edu_form: FormGroup;
  years_array;
  courseValidation = true;
  collegeYearValidation = true;
  monthValidation  = true;
  yearValidation = true;
  attemptValidation  = true;
  semesterValidation  = true;
  instructionValidation = true;
  edu_data;
  course_data;
  user: any;

  constructor(protected api : ApiService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    protected ref: NbDialogRef<EducationalFormBafComponent>) { }

  async ngOnInit() {
    // this.userService.onUserChange()
    // .subscribe((user: any) => this.user = user);
    // if(this.user.role == 'admin'){
    //   this.admin_show = false;
    // }

    this.edu_form = this.formBuilder.group({
      courseNameCtrl: [ '' ,  Validators.required],
      collegeYearCtrl : [ '' ,  Validators.required],
      semesterCtrl : [ '' ,  Validators.required],
      monthCtrl : [ '' ,  Validators.required],
      yearCtrl : [ '' ,  Validators.required],
      overmarksobtCtrl : [ '' ,  Validators.required],
      overmarksoutCtrl : [ '' ,  Validators.required],
      subjectFirstCtrl : [ '' ,  Validators.required],
      markFirstCtrl : [ '' ,  Validators.required],
      markFirstOutCtrl : [ '' ,  Validators.required],
      subjectSecondCtrl : [ '' ,  Validators.required],
      markSecondCtrl : [ '' ,  Validators.required],
      markSecondOutCtrl : [ '' ,  Validators.required],
      subjectThirdCtrl : [ '' ,  Validators.required],
      markThirdCtrl : [ '' ,  Validators.required],
      markThirdOutCtrl : [ '' ,  Validators.required],
      subjectFourthCtrl : [ '' ,  Validators.required],
      markFourthCtrl : [ '' ,  Validators.required],
      markFourthOutCtrl : [ '' ,  Validators.required],
      subjectFifthCtrl : [ ''],
      markFifthCtrl : [ '' ],
      markFifthOutCtrl : [ '' ],
      subjectSixthCtrl : [ '' ],
      markSixthCtrl : [ ''],
      markSixthOutCtrl : [ ''],
      subjectSeventhCtrl : [ ''],
      markSeventhCtrl : [ ''],
      markSeventhOutCtrl : [ ''],
      subjectEighthCtrl : [ ''],
      markEighthCtrl : [ ''],
      markEighthOutCtrl: [ ''],
      instructionCtrl : [ '' ,  Validators.required],
      lecturehourFirstCtrl : [ ''],
      lecturehourSecondCtrl : [ ''],
      lecturehourThirdCtrl : [ ''],
      lecturehourFourthCtrl : [ ''],
      lecturehourFifthCtrl : [ ''],
      lecturehourSixCtrl : [ ''],
      lecturehourSeventhCtrl : [ ''],
      lecturehourEighthCtrl : [ ''],
      practicalhourFirstCtrl : [ ''],
      practicalhourSecondCtrl : [ ''],
      practicalhourThirdCtrl : [ ''],
      practicalhourFourthCtrl : [ ''],
      practicalhourFifthCtrl : [ ''],
      practicalhourSixCtrl : [ ''],
      practicalhourSeventhCtrl : [ ''],
      practicalhourEighthCtrl : [ ''],
      specialInstrctionCtrl: [ ''],
      totalweeksCtrl : [ ''],
    })

    this.api.getCouses()
      .subscribe(
        (data: any) => { 
          if(data['status'] == 200){
            this.course_data = data['data'];
            console.log("this.course_data---->"+JSON.stringify(this.course_data));
          }else{

          }
    });


    const now = new Date().getUTCFullYear();    
    this.years_array = Array(now - (now - 50)).fill('').map((v, idx) => now - idx);
    console.log('this.user_id---->'+this.user_id)
    console.log('this.edu_id---->'+this.edu_id)
    if(this.user_id != null){
      this.api.getEducation(this.edu_id,this.user_id)
      .subscribe(
        (data: any) => { 
          if(data['status'] == 200){
            this.edu_data = data['data'];
            //console.log("this.edu_data---->"+JSON.stringify(this.edu_data));
          }else{
            console.log("NO Data---->");
          }
      });
    }
  }

  saveEdu() {
    this.courseValidation = true;
    this.collegeYearValidation = true;
    this.monthValidation = true;
    this.yearValidation = true;
    this.attemptValidation = true;
    this.instructionValidation = true;
    this.semesterValidation = true;
    if (this.edu_form.valid==false){
      this.edu_form.controls.courseNameCtrl.markAsDirty();
      this.edu_form.controls.overmarksoutCtrl.markAsDirty();
      this.edu_form.controls.overmarksobtCtrl.markAsDirty();
      this.edu_form.controls.subjectFirstCtrl.markAsDirty();
      this.edu_form.controls.markFirstCtrl.markAsDirty();
      this.edu_form.controls.markFirstOutCtrl.markAsDirty();
      this.edu_form.controls.subjectSecondCtrl.markAsDirty();
      this.edu_form.controls.markSecondCtrl.markAsDirty();
      this.edu_form.controls.markSecondOutCtrl.markAsDirty();
      this.edu_form.controls.subjectThirdCtrl.markAsDirty();
      this.edu_form.controls.markThirdCtrl.markAsDirty();
      this.edu_form.controls.markThirdOutCtrl.markAsDirty();
      this.edu_form.controls.subjectFourthCtrl.markAsDirty();
      this.edu_form.controls.markFourthCtrl.markAsDirty();
      this.edu_form.controls.markFourthOutCtrl.markAsDirty();
      this.edu_form.controls.instructionCtrl.markAsDirty();

      if(this.edu_form.controls.instructionCtrl.value === null || this.edu_form.controls.instructionCtrl.value ==='' || this.edu_form.controls.instructionCtrl.value ===undefined){
        this.instructionValidation = false;
      }else {
        this.instructionValidation = true;
      }

      if(this.edu_form.controls.courseNameCtrl.value === null || this.edu_form.controls.courseNameCtrl.value ==='' || this.edu_form.controls.courseNameCtrl.value ===undefined){
        this.courseValidation = false;
      }else {
        this.courseValidation = true;
      }
      
      if(this.edu_form.controls.collegeYearCtrl.value === null || this.edu_form.controls.collegeYearCtrl.value ==='' || this.edu_form.controls.collegeYearCtrl.value ===undefined){
        this.collegeYearValidation = false;
      }else {
        this.collegeYearValidation = true;
      }

      if(this.edu_form.controls.semesterCtrl.value === null || this.edu_form.controls.semesterCtrl.value ==='' || this.edu_form.controls.semesterCtrl.value ===undefined){
        this.semesterValidation = false;
      }else {
        this.semesterValidation = true;
      }

      if(this.edu_form.controls.monthCtrl.value === null || this.edu_form.controls.monthCtrl.value ==='' || this.edu_form.controls.monthCtrl.value ===undefined){
        this.monthValidation = false;
      }else {
        this.monthValidation = true;
      }

      if(this.edu_form.controls.yearCtrl.value === null || this.edu_form.controls.yearCtrl.value ==='' || this.edu_form.controls.yearCtrl.value ===undefined){
        this.yearValidation = false;
      }else {
        this.yearValidation = true;
      }
    }else{
      var edu_data ={
        courseName : this.edu_form.controls.courseNameCtrl.value,
        collegeYear : this.edu_form.controls.collegeYearCtrl.value,
        semester: this.edu_form.controls.semesterCtrl.value,
        month : this.edu_form.controls.monthCtrl.value,
        year : this.edu_form.controls.yearCtrl.value,
        overall_marks_obt : this.edu_form.controls.overmarksobtCtrl.value,
        overall_marks_out : this.edu_form.controls.overmarksoutCtrl.value,
        subjectFirst : this.edu_form.controls.subjectFirstCtrl.value,
        marks_obt_First : this.edu_form.controls.markFirstCtrl.value,
        marks_out_First: this.edu_form.controls.markFirstOutCtrl.value,
        subjectSecond : this.edu_form.controls.subjectSecondCtrl.value,
        marks_obt_Second: this.edu_form.controls.markSecondCtrl.value,
        marks_out_Second: this.edu_form.controls.markSecondOutCtrl.value,
        subjectThird : this.edu_form.controls.subjectThirdCtrl.value,
        marks_obt_Third : this.edu_form.controls.markThirdCtrl.value,
        marks_out_Third : this.edu_form.controls.markThirdOutCtrl.value,
        subjectFourth : this.edu_form.controls.subjectFourthCtrl.value,
        marks_obt_Fourth : this.edu_form.controls.markFourthCtrl.value,
        marks_out_Fourth : this.edu_form.controls.markFourthOutCtrl.value,
        subjectFifth : this.edu_form.controls.subjectFifthCtrl.value,
        marks_obt_Fifth : this.edu_form.controls.markFifthCtrl.value,
        marks_out_Fifth : this.edu_form.controls.markFifthOutCtrl.value,
        subjectSixth : this.edu_form.controls.subjectSixthCtrl.value,
        marks_obt_Six : this.edu_form.controls.markSixthCtrl.value,
        marks_out_Six : this.edu_form.controls.markSixthOutCtrl.value,
        subjectSeventh : this.edu_form.controls.subjectSeventhCtrl.value,
        marks_obt_Seventh : this.edu_form.controls.markSeventhCtrl.value,
        marks_out_Seventh : this.edu_form.controls.markSeventhOutCtrl.value,
        subjectEighth : this.edu_form.controls.subjectEighthCtrl.value,
        marks_obt_Eighth : this.edu_form.controls.markEighthCtrl.value,
        marks_out_Eighth : this.edu_form.controls.markEighthOutCtrl.value,
        user_id : this.user_id,
        medium_instruction : this.edu_form.controls.instructionCtrl.value,
        id : this.edu_id,
        lecture_hour_First : this.edu_form.controls.lecturehourFirstCtrl.value,
        lecture_hour_Second : this.edu_form.controls.lecturehourSecondCtrl.value,
        lecture_hour_Third : this.edu_form.controls.lecturehourThirdCtrl.value,
        lecture_hour_Fourth : this.edu_form.controls.lecturehourFourthCtrl.value,
        lecture_hour_Fifth : this.edu_form.controls.lecturehourFifthCtrl.value,
        lecture_hour_Six : this.edu_form.controls.lecturehourSixCtrl.value,
        lecture_hour_Seventh : this.edu_form.controls.lecturehourSeventhCtrl.value,
        lecture_hour_Eighth : this.edu_form.controls.lecturehourEighthCtrl.value,
        practical_hour_First : this.edu_form.controls.practicalhourFirstCtrl.value,
        practical_hour_Second : this.edu_form.controls.practicalhourSecondCtrl.value,
        practical_hour_Third : this.edu_form.controls.practicalhourThirdCtrl.value,
        practical_hour_Fourth : this.edu_form.controls.practicalhourFourthCtrl.value,
        practical_hour_Fifth : this.edu_form.controls.practicalhourFifthCtrl.value,
        practical_hour_Six : this.edu_form.controls.practicalhourSixCtrl.value,
        practical_hour_Seventh : this.edu_form.controls.practicalhourSeventhCtrl.value,
        practical_hour_Eighth : this.edu_form.controls.practicalhourEighthCtrl.value,
        total_weeks : this.edu_form.controls.totalweeksCtrl.value,
        special_instruction : this.edu_form.controls.specialInstrctionCtrl.value,
      }
  
      this.api.setEducationalValues(edu_data)
      .subscribe(
        (data: any) => {  
          this.ref.close(data);
          err => console.error(err) 
      });
    }
  }

  dismiss() {
    this.ref.close();
  }

}