import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
import { Router,ActivatedRoute } from '@angular/router';
import { NbAuthJWTToken,NbAuthService } from '@nebular/auth';
import {NbGlobalPhysicalPosition, NbToastrService} from '@nebular/theme';
@Component({
  selector: 'ngx-admin-otp',
  templateUrl: './admin-otp.component.html',
  styleUrls: ['./admin-otp.component.scss']
})
export class AdminOtpComponent {
  otp: any;
  alert: any;
  alertflag: number;
  user_id: any;
  enterOtp:any;
  otpValidation: boolean;
  constructor(private router : Router,
    private route : ActivatedRoute,
    protected api : ApiService,
    private authService : NbAuthService,
    private toasterService: NbToastrService) {
      this.authService.onTokenChange()
        .subscribe((token: NbAuthJWTToken) => {
        // if(token.getPayload()['role'] !="admin"){
        //   this.router.navigate(['auth/logout'])
        // }
        if(token.getPayload()['role'] =="admin" || token.getPayload()['role'] =="sub-admin"){
          this.user_id=token.getPayload()['id']
        }else{
          this.router.navigate(['auth/logout'])
        }
      });
    }

  ngOnInit() {
    this.api.sendOtp().subscribe(data=>{
      if(data['status'] == 200){
        this.otp = data['data']
      }
    })
  }

  cancel(){
    this.router.navigate(['auth/logout']);
  }


  verify(){console.log("verify");
  const config = {
    position: NbGlobalPhysicalPosition.TOP_RIGHT
  };
    this.enterOtp = (document.getElementById('enterOtp') as HTMLInputElement).value;console.log("rhis.enterotp",this.enterOtp,"this.otp",this.otp);
    if(this.otp === this.enterOtp){  
        this.api.updateOtp().subscribe(data=>{
          if(data['status'] == 200){console.log("200");
          
            //this.router.navigate(['pages/admin-dashboard'])
            this.router.navigate(['pages/student-management'])
          } else{
            this.toasterService.danger('Please enter right otp..........', 'Failure', config);
            this.otpValidation = false;
           }
        })     
    }
}

  resend_otp(){
    const config = {
      position: NbGlobalPhysicalPosition.TOP_RIGHT
    };
    this.api.resendOtp(this.user_id).subscribe(data=>{
      if(data['status'] == 200){
        this.otp=data['data']
        this.toasterService.show('OTP SENT..........', 'Success', config);
      }else{
        this.toasterService.danger('Somethig went wrong..........', 'Failure', config); 
      }
    })
  }
}
