import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from './../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { FormBuilder, FormGroup , Validators} from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { UserService } from './../../@core/data/users.service';



@Component({
  selector: 'educational',
  template: `
  <style>
  .mat-form-field--inline {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .mat-form-field--inline .mat-form-field {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
    }

    .mat-form-field--inline .mat-form-field:nth-child(1) {
        margin-right: 10px;
    }
    
    @media (max-width: 767px) {
      #selectLine{  
        width: 65%;
      } 
    }
      
    @media (min-width: 1200px) {
      #selectLine{  
        width: 145%;
      } 
    }
      
    @media (min-width: 768px) {
      #selectLine{  
        width: 65%;
      } 
    }
      
    @media (min-width: 992px) {
      #selectLine{  
        width: 145%;
      } 
    }

    .example-full-width {
      
    }
  </style>
  <nb-card status="success"  [style.height.px]="630" [style.overflow]="'auto'">
    <nb-card-header>Add Educational Details</nb-card-header>
    <nb-card-body> 
      <span *ngIf="instructionValidation===false" style="color:red;">Please add medium of instruction which is below subjects.</span>
      <form [formGroup]="edu_form"  class="step-container">
        <div class="row">
          <div class="col-md-3">Course Name : </div>
          <div class="col-md-4"> 
            <!--<input  ngModel="{{edu_data?.CourseName}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.courseNameCtrl.invalid && (edu_form.controls.courseNameCtrl.dirty || edu_form.controls.courseNameCtrl.touched)}" formControlName="courseNameCtrl" placeholder="" id="" class="form-control">-->
            <mat-form-field >
              <mat-select ngModel="{{edu_data?.CourseName}}" name="coursename" formControlName="courseNameCtrl">
                  <mat-option value="{{course.CourseShortForm}}" *ngFor="let course of course_data">{{course.CourseName}}</mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="courseValidation===false" style="color:red;">Please select College Year.</span>  
          </div>
          <div class="col-md-3">SGPI : </div>
          <div class="col-md-2">
            <input  ngModel="{{edu_data?.overall_gpa_sgpi}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.overAllgpasgpiCtrl.invalid && (edu_form.controls.overAllgpasgpiCtrl.dirty || edu_form.controls.overAllgpasgpiCtrl.touched)}" formControlName="overAllgpasgpiCtrl" placeholder="" id="" class="form-control"> 
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">College Year : </div>
          <div class="col-md-2">
            <mat-form-field id="selectLine" >
              <mat-select ngModel="{{edu_data?.CollegeYear}}" name="collegeYear" formControlName="collegeYearCtrl">
                  <mat-option value="First">First</mat-option>
                  <mat-option value="Second">Second</mat-option>
                  <mat-option value="Third">Third</mat-option>
                  <mat-option value="Fourth">Fourth</mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="collegeYearValidation===false" style="color:red;">Please select College Year.</span> 
          </div>
          <div class="col-md-3">Semester : </div>
          <div class="col-md-2">
              <mat-form-field id="selectLine" >
                  <mat-select ngModel="{{edu_data?.Semester}}" name="semester" formControlName="semesterCtrl">
                      <mat-option value="I">I</mat-option>
                      <mat-option value="II">II</mat-option>
                      <mat-option value="III">III</mat-option>
                      <mat-option value="IV">IV</mat-option>
                  </mat-select>
              </mat-form-field>
              <span *ngIf="semesterValidation===false" style="color:red;">Please select semester</span> 
          </div>
      </div>
      <div class="row">
          <div class="col-md-3">Month : </div>
          <div class="col-md-2">
            <mat-form-field id="selectLine" >
              <mat-select  ngModel="{{edu_data?.Month}}" name="month" formControlName="monthCtrl">
                <mat-option value="January">January</mat-option>
                <mat-option value="February">February</mat-option>
                <mat-option value="March">March</mat-option>
                <mat-option value="April">April</mat-option>
                <mat-option value="May">May</mat-option>
                <mat-option value="June">June</mat-option>
                <mat-option value="July">July</mat-option>
                <mat-option value="August">August</mat-option>
                <mat-option value="September">September</mat-option>
                <mat-option value="October">October</mat-option>
                <mat-option value="November">November</mat-option>
                <mat-option value="December">December</mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="monthValidation===false" style="color:red;">Please select month</span> 
          </div>
          <div class="col-md-3">Year : </div>
          <div class="col-md-2">
              <mat-form-field id="selectLine" >
                  <mat-select ngModel="{{edu_data?.Year}}" name="year" formControlName="yearCtrl">
                      <mat-option value="{{years}}" *ngFor="let years of years_array">{{years}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <span *ngIf="yearValidation===false" style="color:red;">Please select year.</span> 
          </div>
      </div>
      <div class="row">
          <div class="col-md-3">Total Credits Points: </div>
          <div class="col-md-2">
            <input  ngModel="{{edu_data?.overall_marks_obt}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.overmarksobtCtrl.invalid && (edu_form.controls.overmarksobtCtrl.dirty || edu_form.controls.overmarksobtCtrl.touched)}" formControlName="overmarksobtCtrl" placeholder="Total C" id="" class="form-control"> 
          </div>
          <div class="col-md-3">Total Credits Out of : </div>
          <div class="col-md-2">
            <input  ngModel="{{edu_data?.overall_marks_out}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.overmarksoutCtrl.invalid && (edu_form.controls.overmarksoutCtrl.dirty || edu_form.controls.overmarksoutCtrl.touched)}" formControlName="overmarksoutCtrl" placeholder="Total (C*G)" id="" class="form-control"> 
          </div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 1 :</div>
        <div class="col-md-3"><input  ngModel="{{edu_data?.Subject_First}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.subjectFirstCtrl.invalid && (edu_form.controls.subjectFirstCtrl.dirty || edu_form.controls.subjectFirstCtrl.touched)}" formControlName="subjectFirstCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_First}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.gradeFirstCtrl.invalid && (edu_form.controls.gradeFirstCtrl.dirty || edu_form.controls.gradeFirstCtrl.touched)}" formControlName="gradeFirstCtrl" placeholder="Grades" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_points_First}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.gradepointsFirstCtrl.invalid && (edu_form.controls.gradepointsFirstCtrl.dirty || edu_form.controls.gradepointsFirstCtrl.touched)}" formControlName="gradepointsFirstCtrl" placeholder="Grade points G" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_First}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.markFirstCtrl.invalid && (edu_form.controls.markFirstCtrl.dirty || edu_form.controls.markFirstCtrl.touched)}" formControlName="markFirstCtrl" placeholder="Credit Points C" id="" class="form-control"></div>
        <div class="col-md-1"><input  ngModel="{{edu_data?.grade_credits_First}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.gradecreditsFirstCtrl.invalid && (edu_form.controls.gradecreditsFirstCtrl.dirty || edu_form.controls.gradecreditsFirstCtrl.touched)}" formControlName="gradecreditsFirstCtrl" placeholder="C * G" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 2 :</div>
        <div class="col-md-3"><input  ngModel="{{edu_data?.Subject_Second}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.subjectSecondCtrl.invalid && (edu_form.controls.subjectSecondCtrl.dirty || edu_form.controls.subjectSecondCtrl.touched)}" formControlName="subjectSecondCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_Second}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.gradeSecondCtrl.invalid && (edu_form.controls.gradeSecondCtrl.dirty || edu_form.controls.gradeSecondCtrl.touched)}" formControlName="gradeSecondCtrl" placeholder="Grades" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_points_Second}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.gradepointsSecondCtrl.invalid && (edu_form.controls.gradepointsSecondCtrl.dirty || edu_form.controls.gradepointsSecondCtrl.touched)}" formControlName="gradepointsSecondCtrl" placeholder="Grade points G" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Second}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.markSecondCtrl.invalid && (edu_form.controls.markSecondCtrl.dirty || edu_form.controls.markSecondCtrl.touched)}" formControlName="markSecondCtrl" placeholder="Credit Points C" id="" class="form-control"></div>
        <div class="col-md-1"><input  ngModel="{{edu_data?.grade_credits_Second}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.gradecreditsSecondCtrl.invalid && (edu_form.controls.gradecreditsSecondCtrl.dirty || edu_form.controls.gradecreditsSecondCtrl.touched)}" formControlName="gradecreditsSecondCtrl" placeholder="C * G" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 3 :</div>
        <div class="col-md-3"><input  ngModel="{{edu_data?.Subject_Third}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.subjectThirdCtrl.invalid && (edu_form.controls.subjectThirdCtrl.dirty || edu_form.controls.subjectThirdCtrl.touched)}" formControlName="subjectThirdCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_Third}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.gradeThirdCtrl.invalid && (edu_form.controls.gradeThirdCtrl.dirty || edu_form.controls.gradeThirdCtrl.touched)}" formControlName="gradeThirdCtrl" placeholder="Grades" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_points_Third}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.gradepointsThirdCtrl.invalid && (edu_form.controls.gradepointsThirdCtrl.dirty || edu_form.controls.gradepointsThirdCtrl.touched)}" formControlName="gradepointsThirdCtrl" placeholder="Grade points G" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Third}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.markThirdCtrl.invalid && (edu_form.controls.markThirdCtrl.dirty || edu_form.controls.markThirdCtrl.touched)}" formControlName="markThirdCtrl" placeholder="Credit Points C" id="" class="form-control"></div>
        <div class="col-md-1"><input  ngModel="{{edu_data?.grade_credits_Third}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.gradecreditsThirdCtrl.invalid && (edu_form.controls.gradecreditsThirdCtrl.dirty || edu_form.controls.gradecreditsThirdCtrl.touched)}" formControlName="gradecreditsThirdCtrl" placeholder="C * G" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 4 :</div>
        <div class="col-md-3"><input  ngModel="{{edu_data?.Subject_Fourth}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.subjectFourthCtrl.invalid && (edu_form.controls.subjectFourthCtrl.dirty || edu_form.controls.subjectFourthCtrl.touched)}" formControlName="subjectFourthCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_Fourth}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.gradeFourthCtrl.invalid && (edu_form.controls.gradeFourthCtrl.dirty || edu_form.controls.gradeFourthCtrl.touched)}" formControlName="gradeFourthCtrl" placeholder="Grades" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_points_Fourth}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.gradepointsFourthCtrl.invalid && (edu_form.controls.gradepointsFourthCtrl.dirty || edu_form.controls.gradepointsFourthCtrl.touched)}" formControlName="gradepointsFourthCtrl" placeholder="Grade points G" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Fourth}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.markFourthCtrl.invalid && (edu_form.controls.markFourthCtrl.dirty || edu_form.controls.markFourthCtrl.touched)}" formControlName="markFourthCtrl" placeholder="Credit Points C" id="" class="form-control"></div>
        <div class="col-md-1"><input  ngModel="{{edu_data?.grade_credits_Fourth}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.gradecreditsFourthCtrl.invalid && (edu_form.controls.gradecreditsFourthCtrl.dirty || edu_form.controls.gradecreditsFourthCtrl.touched)}" formControlName="gradecreditsFourthCtrl" placeholder="C * G" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 5 :</div>
        <div class="col-md-3"><input  ngModel="{{edu_data?.Subject_Fifth}}" nbInput type="text"  formControlName="subjectFifthCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_Fifth}}" nbInput type="text" formControlName="gradeFifthCtrl" placeholder="Grades" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_points_Fifth}}" nbInput type="text" formControlName="gradepointsFifthCtrl" placeholder="Grade points G" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Fifth}}" nbInput type="text" formControlName="markFifthCtrl" placeholder="Credit Points C" id="" class="form-control"></div>
        <div class="col-md-1"><input  ngModel="{{edu_data?.grade_credits_Fifth}}" nbInput type="text" formControlName="gradecreditsFifthCtrl" placeholder="C * G" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 6 :</div>
        <div class="col-md-3"><input  ngModel="{{edu_data?.Subject_Six}}" nbInput type="text"  formControlName="subjectSixthCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_Six}}" nbInput type="text" formControlName="gradeSixthCtrl" placeholder="Grades" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_points_Six}}" nbInput type="text" formControlName="gradepointsSixthCtrl" placeholder="Grade points G" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Six}}" nbInput type="text" formControlName="markSixthCtrl" placeholder="Credit Points C" id="" class="form-control"></div>
        <div class="col-md-1"><input  ngModel="{{edu_data?.grade_credits_Six}}" nbInput type="text" formControlName="gradecreditsSixthCtrl" placeholder="C * G" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 7 :</div>
        <div class="col-md-3"><input  ngModel="{{edu_data?.Subject_Seventh}}" nbInput type="text"  formControlName="subjectSeventhCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_Seventh}}" nbInput type="text" formControlName="gradeSeventhCtrl" placeholder="Grades" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_points_Seventh}}" nbInput type="text" formControlName="gradepointsSeventhCtrl" placeholder="Grade points G" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Seventh}}" nbInput type="text" formControlName="markSeventhCtrl" placeholder="Credit Points C" id="" class="form-control"></div>
        <div class="col-md-1"><input  ngModel="{{edu_data?.grade_credits_Seventh}}" nbInput type="text" formControlName="gradecreditsSeventhCtrl" placeholder="C * G" id="" class="form-control"></div>      
      </div>
      <div class="row">
        <div class="col-md-1">Subject 8 :</div>
        <div class="col-md-3"><input  ngModel="{{edu_data?.Subject_Eighth}}" nbInput type="text"  formControlName="subjectEighthCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_Eighth}}" nbInput type="text" formControlName="gradeEighthCtrl" placeholder="Grades" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_points_Eighth}}" nbInput type="text" formControlName="gradepointsEighthCtrl" placeholder="Grade points G" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Eighth}}" nbInput type="text" formControlName="markEighthCtrl" placeholder="Credit Points C" id="" class="form-control"></div>
        <div class="col-md-1"><input  ngModel="{{edu_data?.grade_credits_Eighth}}" nbInput type="text" formControlName="gradecreditsEighthCtrl" placeholder="C * G" id="" class="form-control"></div>
      </div>
      <!-- <div class="row">
        <div class="col-md-1">Subject 9 :</div>
        <div class="col-md-3"><input  ngModel="{{edu_data?.Subject_Ninth}}" nbInput type="text"  formControlName="subjectNinthCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Ninth}}" nbInput type="text" formControlName="markNinthOutCtrl" placeholder="Course Credits" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_Ninth}}" nbInput type="text" formControlName="gradeNinthCtrl" placeholder="Grades" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Ninth}}" nbInput type="text" formControlName="markNinthCtrl" placeholder="Credit Points C" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_points_Ninth}}" nbInput type="text" formControlName="gradepointsNinthCtrl" placeholder="Grade points G" id="" class="form-control"></div>
        <div class="col-md-1"><input  ngModel="{{edu_data?.grade_credits_Ninth}}" nbInput type="text" formControlName="gradecreditsNinthCtrl" placeholder="C * G" id="" class="form-control"></div>
      </div>
      <div class="row">
        <div class="col-md-1">Subject 10 :</div>
        <div class="col-md-3"><input  ngModel="{{edu_data?.Subject_Tenth}}" nbInput type="text"  formControlName="subjectTenthCtrl" placeholder="name" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_out_Tenth}}" nbInput type="text" formControlName="markTenthOutCtrl" placeholder="Course Credits" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_Tenth}}" nbInput type="text" formControlName="gradeTenthCtrl" placeholder="Grades" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.marks_obt_Tenth}}" nbInput type="text" formControlName="markTenthCtrl" placeholder="Credit Points C" id="" class="form-control"></div>
        <div class="col-md-2"><input  ngModel="{{edu_data?.grade_points_Tenth}}" nbInput type="text" formControlName="gradepointsTenthCtrl" placeholder="Grade points G" id="" class="form-control"></div>
        <div class="col-md-1"><input  ngModel="{{edu_data?.grade_credits_Tenth}}" nbInput type="text" formControlName="gradecreditsTenthCtrl" placeholder="C * G" id="" class="form-control"></div>
      </div> -->
      <div class="row">
          <div class="col-md-3">Medium of instruction : </div>
          <div class="col-md-3"> <input  ngModel="{{edu_data?.medium_instruction}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.instructionCtrl.invalid && (edu_form.controls.instructionCtrl.dirty || edu_form.controls.instructionCtrl.touched)}" formControlName="instructionCtrl" placeholder="" id="" class="form-control"> </div>
          <div class="col-md-3" *ngIf="admin_show == 'true'">Special Instruction : </div>
          <div class="col-md-3" *ngIf="admin_show == 'true'"><input  ngModel="{{edu_data?.special_instruction}}" nbInput type="text" formControlName="specialInstrctionCtrl" placeholder="" id="" class="form-control"> </div>
        </div>
        <div class="row">
          <div class="col-md-3" *ngIf="admin_show == 'true'">Final Grade Special Instruction : </div>
          <div class="col-md-3" *ngIf="admin_show == 'true'"><input  ngModel="{{edu_data?.final_grade_special_instruction}}" nbInput type="text" formControlName="finalgradespecialInstrctionCtrl" placeholder="" id="" class="form-control"> </div>
          <div class="col-md-3" *ngIf="admin_show == 'true'">Total Weeks : </div>
          <div class="col-md-3" *ngIf="admin_show == 'true'">
            <input  ngModel="{{edu_data?.total_weeks}}" nbInput type="text" formControlName="totalweeksCtrl" placeholder="" id="" class="form-control"> 
          </div>        
        </div>
      </form>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton hero status="primary" (click)="dismiss()">Close</button> 
        <button *ngIf="amount_paid == 'false'" nbButton status="info" (click)="saveEdu()">save</button>
    </nb-card-footer>
</nb-card>
  `
})
export class addMasterEducationalDetailsSemesterSGPI implements OnInit {
  FeedbackForm : FormGroup;
  @Input() user_id: any;
  @Input() edu_id: any;
  @Input() amount_paid:string;
  @Input() admin_show:string;
  validations_flag = false;
  feedback_message ;
  showExperience = false;

  edu_form: FormGroup;
  years_array;
  courseValidation = true;
  collegeYearValidation = true;
  semesterValidation  = true;
  monthValidation  = true;
  yearValidation = true;
  attemptValidation  = true;
  instructionValidation = true;
  edu_data;
  course_data;
  user: any;

  constructor(protected api : ApiService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    protected ref: NbDialogRef<addMasterEducationalDetailsSemesterSGPI>) { }

  async ngOnInit() {
    // this.userService.onUserChange()
    // .subscribe((user: any) => this.user = user);
    // if(this.user.role == 'admin'){
    //   this.admin_show = false;
    // }

    this.edu_form = this.formBuilder.group({
      courseNameCtrl: [ '' ,  Validators.required],
      collegeYearCtrl : [ '' ,  Validators.required],
      semesterCtrl : [ '' ,  Validators.required],
      monthCtrl : [ '' ,  Validators.required],
      yearCtrl : [ '' ,  Validators.required],
      overAllgpasgpiCtrl : [ '' ,  Validators.required],
      overmarksobtCtrl : [ '' ,  Validators.required],
      overmarksoutCtrl : [ '' ,  Validators.required],
      subjectFirstCtrl : [ '' ,  Validators.required],
      markFirstCtrl : [ '' ,  Validators.required],
      gradeFirstCtrl : [ '' ,  Validators.required],
      gradepointsFirstCtrl : [ '' ,  Validators.required],
      gradecreditsFirstCtrl : [ '' ,  Validators.required],
      subjectSecondCtrl : [ '' ,  Validators.required],
      markSecondCtrl : [ '' ,  Validators.required],
      gradeSecondCtrl : [ '' ,  Validators.required],
      gradepointsSecondCtrl : [ '' ,  Validators.required],
      gradecreditsSecondCtrl : [ '' ,  Validators.required],
      subjectThirdCtrl : [ '' ,  Validators.required],
      markThirdCtrl : [ '' ,  Validators.required],
      gradeThirdCtrl : [ '' ,  Validators.required],
      gradepointsThirdCtrl : [ '' ,  Validators.required],
      gradecreditsThirdCtrl : [ '' ,  Validators.required],
      subjectFourthCtrl : [ '' ,  Validators.required],
      markFourthCtrl : [ '' ,  Validators.required],
      gradeFourthCtrl : [ '' ,  Validators.required],
      gradepointsFourthCtrl : [ '' ,  Validators.required],
      gradecreditsFourthCtrl : [ '' ,  Validators.required],
      subjectFifthCtrl : [ ''],
      markFifthCtrl : [ '' ],
      markFifthOutCtrl : [ '' ],
      gradeFifthCtrl : [''],
      gradepointsFifthCtrl : [ ''],
      gradecreditsFifthCtrl : [ ''],
      subjectSixthCtrl : [ '' ],
      markSixthCtrl : [ ''],
      markSixthOutCtrl : [ ''],
      gradeSixthCtrl : [''],
      gradepointsSixthCtrl : [ ''],
      gradecreditsSixthCtrl : [ ''],
      subjectSeventhCtrl : [ ''],
      markSeventhCtrl : [ ''],
      markSeventhOutCtrl : [ ''],
      gradeSeventhCtrl : [''],
      gradepointsSeventhCtrl : [ ''],
      gradecreditsSeventhCtrl : [ ''],
      subjectEighthCtrl : [ ''],
      markEighthCtrl : [ ''],
      markEighthOutCtrl: [ ''],
      gradeEighthCtrl :[''],
      gradepointsEighthCtrl : [ ''],
      gradecreditsEighthCtrl : [ ''],
      instructionCtrl : [ '' ,  Validators.required],
      // subjectNinthCtrl : [ ''],
      // markNinthCtrl : [ ''],
      // markNinthOutCtrl: [ ''],
      // gradeNinthCtrl :[''],
      // gradepointsNinthCtrl : [ ''],
      // gradecreditsNinthCtrl : [ ''],
      // subjectTenthCtrl : [ ''],
      // markTenthCtrl : [ ''],
      // markTenthOutCtrl: [ ''],
      // gradeTenthCtrl :[''],
      // gradepointsTenthCtrl : [ ''],
      // gradecreditsTenthCtrl : [ ''],
      specialInstrctionCtrl: [ ''],
      finalgradespecialInstrctionCtrl: [ ''],
      totalweeksCtrl : [ ''],
    })

    this.api.getCouses()
      .subscribe(
        (data: any) => { 
          if(data['status'] == 200){
            this.course_data = data['data'];
            //console.log("this.course_data---->"+JSON.stringify(this.course_data));
          }else{

          }
    });


    const now = new Date().getUTCFullYear();    
    this.years_array = Array(now - (now - 50)).fill('').map((v, idx) => now - idx);
    console.log('this.user_id---->'+this.user_id)
    console.log('this.edu_id---->'+this.edu_id)
    if(this.user_id != null){
      this.api.getEducation(this.edu_id,this.user_id)
      .subscribe(
        (data: any) => { 
          if(data['status'] == 200){
            this.edu_data = data['data'];
            //console.log("this.edu_data---->"+JSON.stringify(this.edu_data));
          }else{
            console.log("NO Data---->");
          }
      });
    }
  }

  saveEdu() {
    this.courseValidation = true;
    this.collegeYearValidation = true;
    this.semesterValidation = true;
    this.monthValidation = true;
    this.yearValidation = true;
    this.attemptValidation = true;
    this.instructionValidation = true;

    if (this.edu_form.valid==false){
      this.edu_form.controls.courseNameCtrl.markAsDirty();
      this.edu_form.controls.overAllgpasgpiCtrl.markAsDirty();
      this.edu_form.controls.overmarksoutCtrl.markAsDirty();
      this.edu_form.controls.overmarksobtCtrl.markAsDirty();
      this.edu_form.controls.subjectFirstCtrl.markAsDirty();
      this.edu_form.controls.markFirstCtrl.markAsDirty();
      this.edu_form.controls.gradeFirstCtrl.markAsDirty();
      this.edu_form.controls.gradepointsFirstCtrl.markAsDirty();
      this.edu_form.controls.gradecreditsFirstCtrl.markAsDirty();
      this.edu_form.controls.subjectSecondCtrl.markAsDirty();
      this.edu_form.controls.markSecondCtrl.markAsDirty();
      this.edu_form.controls.gradeSecondCtrl.markAsDirty();
      this.edu_form.controls.gradepointsSecondCtrl.markAsDirty();
      this.edu_form.controls.gradecreditsSecondCtrl.markAsDirty();
      this.edu_form.controls.subjectThirdCtrl.markAsDirty();
      this.edu_form.controls.markThirdCtrl.markAsDirty();
      this.edu_form.controls.gradeThirdCtrl.markAsDirty();
      this.edu_form.controls.gradepointsThirdCtrl.markAsDirty();
      this.edu_form.controls.gradecreditsThirdCtrl.markAsDirty();
      this.edu_form.controls.subjectFourthCtrl.markAsDirty();
      this.edu_form.controls.markFourthCtrl.markAsDirty();
      this.edu_form.controls.gradeFourthCtrl.markAsDirty();
      this.edu_form.controls.gradepointsFourthCtrl.markAsDirty();
      this.edu_form.controls.gradecreditsFourthCtrl.markAsDirty();
      this.edu_form.controls.instructionCtrl.markAsDirty();

      if(this.edu_form.controls.instructionCtrl.value === null || this.edu_form.controls.instructionCtrl.value ==='' || this.edu_form.controls.instructionCtrl.value ===undefined){
        this.instructionValidation = false;
      }else {
        this.instructionValidation = true;
      }

      if(this.edu_form.controls.courseNameCtrl.value === null || this.edu_form.controls.courseNameCtrl.value ==='' || this.edu_form.controls.courseNameCtrl.value ===undefined){
        this.courseValidation = false;
      }else {
        this.courseValidation = true;
      }
      
      if(this.edu_form.controls.collegeYearCtrl.value === null || this.edu_form.controls.collegeYearCtrl.value ==='' || this.edu_form.controls.collegeYearCtrl.value ===undefined){
        this.collegeYearValidation = false;
      }else {
        this.collegeYearValidation = true;
      }

      if(this.edu_form.controls.semesterCtrl.value === null || this.edu_form.controls.semesterCtrl.value ==='' || this.edu_form.controls.semesterCtrl.value ===undefined){
        this.semesterValidation = false;
      }else {
        this.semesterValidation = true;
      }

      if(this.edu_form.controls.monthCtrl.value === null || this.edu_form.controls.monthCtrl.value ==='' || this.edu_form.controls.monthCtrl.value ===undefined){
        this.monthValidation = false;
      }else {
        this.monthValidation = true;
      }

      if(this.edu_form.controls.yearCtrl.value === null || this.edu_form.controls.yearCtrl.value ==='' || this.edu_form.controls.yearCtrl.value ===undefined){
        this.yearValidation = false;
      }else {
        this.yearValidation = true;
      }
    }else{
      var edu_data ={
        courseName : this.edu_form.controls.courseNameCtrl.value,
        collegeYear : this.edu_form.controls.collegeYearCtrl.value,
        semester: this.edu_form.controls.semesterCtrl.value,
        month : this.edu_form.controls.monthCtrl.value,
        year : this.edu_form.controls.yearCtrl.value,
        overall_gpa_sgpi : this.edu_form.controls.overAllgpasgpiCtrl.value,
        overall_marks_obt : this.edu_form.controls.overmarksobtCtrl.value,
        overall_marks_out : this.edu_form.controls.overmarksoutCtrl.value,
        subjectFirst : this.edu_form.controls.subjectFirstCtrl.value,
        marks_obt_First : this.edu_form.controls.markFirstCtrl.value,
        markFirst : this.edu_form.controls.gradeFirstCtrl.value,
        grade_points_First  : this.edu_form.controls.gradepointsFirstCtrl.value,
        grade_credits_First  : this.edu_form.controls.gradecreditsFirstCtrl.value,
        subjectSecond : this.edu_form.controls.subjectSecondCtrl.value,
        marks_obt_Second: this.edu_form.controls.markSecondCtrl.value,
        markSecond: this.edu_form.controls.gradeSecondCtrl.value,
        grade_points_Second  : this.edu_form.controls.gradepointsSecondCtrl.value,
        grade_credits_Second  : this.edu_form.controls.gradecreditsSecondCtrl.value,
        subjectThird : this.edu_form.controls.subjectThirdCtrl.value,
        marks_obt_Third : this.edu_form.controls.markThirdCtrl.value,
        markThird : this.edu_form.controls.gradeThirdCtrl.value,
        grade_points_Third  : this.edu_form.controls.gradepointsThirdCtrl.value,
        grade_credits_Third  : this.edu_form.controls.gradecreditsThirdCtrl.value,
        subjectFourth : this.edu_form.controls.subjectFourthCtrl.value,
        marks_obt_Fourth : this.edu_form.controls.markFourthCtrl.value,
        markFourth : this.edu_form.controls.gradeFourthCtrl.value,
        grade_points_Fourth  : this.edu_form.controls.gradepointsFourthCtrl.value,
        grade_credits_Fourth  : this.edu_form.controls.gradecreditsFourthCtrl.value,
        subjectFifth : this.edu_form.controls.subjectFifthCtrl.value,
        marks_obt_Fifth : this.edu_form.controls.markFifthCtrl.value,
        markFifth : this.edu_form.controls.gradeFifthCtrl.value,
        grade_points_Fifth  : this.edu_form.controls.gradepointsFifthCtrl.value,
        grade_credits_Fifth  : this.edu_form.controls.gradecreditsFifthCtrl.value,
        subjectSixth : this.edu_form.controls.subjectSixthCtrl.value,
        marks_obt_Six : this.edu_form.controls.markSixthCtrl.value,
        markSixth : this.edu_form.controls.gradeSixthCtrl.value,
        grade_points_Six  : this.edu_form.controls.gradepointsSixthCtrl.value,
        grade_credits_Six  : this.edu_form.controls.gradecreditsSixthCtrl.value,
        subjectSeventh : this.edu_form.controls.subjectSeventhCtrl.value,
        marks_obt_Seventh : this.edu_form.controls.markSeventhCtrl.value,
        markSeventh : this.edu_form.controls.gradeSeventhCtrl.value,
        grade_points_Seventh  : this.edu_form.controls.gradepointsSeventhCtrl.value,
        grade_credits_Seventh  : this.edu_form.controls.gradecreditsSeventhCtrl.value,
        subjectEighth : this.edu_form.controls.subjectEighthCtrl.value,
        marks_obt_Eighth : this.edu_form.controls.markEighthCtrl.value,
        markEighth : this.edu_form.controls.gradeEighthCtrl.value,
        grade_points_Eighth  : this.edu_form.controls.gradepointsEighthCtrl.value,
        grade_credits_Eighth  : this.edu_form.controls.gradecreditsEighthCtrl.value,
        // subjectNinth : this.edu_form.controls.subjectNinthCtrl.value,
        // marks_obt_Ninth : this.edu_form.controls.markNinthCtrl.value,
        // marks_out_Ninth : this.edu_form.controls.markNinthOutCtrl.value,
        // markNinth : this.edu_form.controls.gradeNinthCtrl.value,
        // grade_points_Ninth  : this.edu_form.controls.gradepointsNinthCtrl.value,
        // grade_credits_Ninth  : this.edu_form.controls.gradecreditsNinthCtrl.value,
        // subjectTenth : this.edu_form.controls.subjectTenthCtrl.value,
        // marks_obt_Tenth : this.edu_form.controls.markTenthCtrl.value,
        // marks_out_Tenth : this.edu_form.controls.markTenthOutCtrl.value,
        // markTenth : this.edu_form.controls.gradeTenthCtrl.value,
        // grade_points_Tenth  : this.edu_form.controls.gradepointsTenthCtrl.value,
        // grade_credits_Tenth  : this.edu_form.controls.gradecreditsTenthCtrl.value,
        user_id : this.user_id,
        medium_instruction : this.edu_form.controls.instructionCtrl.value,
        id : this.edu_id,
        special_instruction : this.edu_form.controls.specialInstrctionCtrl.value,
        final_grade_special_instruction : this.edu_form.controls.finalgradespecialInstrctionCtrl.value,
        total_weeks : this.edu_form.controls.totalweeksCtrl.value,
      }
  
      this.api.setEducationalValues(edu_data)
      .subscribe(
        (data: any) => {  
          this.ref.close(data);
          err => console.error(err) 
      });
    }
  }

  dismiss() {
    this.ref.close();
  }

}
