import { Component, Input} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Router} from '@angular/router';
import { ApiService } from '../../shared/api.service';
import {  NbToastrService } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

@Component({
    selector: 'nb-dialog',
    template: `
    <style>
        @media (min-width: 768px) {
            .offset-md-3 {
            margin-left: 0%;
            }
    
            .col-md-6 {
                max-width: 100%;
            }
        }
    </style>
    <nb-card  class="col-md-6 offset-md-3" [style.height.px]="600" [style.width.px]="950">
        <nb-card-body>
        <nb-card-header>
            <nb-actions size="large" style="float: right;">
                <nb-action icon="nb-close"  (click)="close()"></nb-action>
            </nb-actions>
        </nb-card-header>
            
            <div *ngIf = "imgType !='pdf' && imgType != 'docx'">
                <img [src]="arr" height="500px;" width="500px;" alt="Random first slide">
            </div>
            
            <div  *ngIf = "imgType =='pdf'" >
                    <div class="pdf-container">
                    <pdf-viewer [src]="arr" 
                    [render-text]="true"
                    style="display: block;"
                    ></pdf-viewer>
                    </div>
                
            </div>

            <div *ngIf="imgType == 'docx' || imgType == 'DOCX'">
                <ngx-doc-viewer [url]="arr" viewer="google"   style="width:100%;height:93vh;">
                </ngx-doc-viewer>
            </div>

            
        </nb-card-body>
    </nb-card>
    `,
    })
export class previewImg {
    @Input() arr: any;
    @Input() imgType : any;
    auto;
    user_id;
    constructor(protected ref: NbDialogRef<previewImg>,
      private router : Router,
      private authService: NbAuthService,
      protected api : ApiService,
      private toastrService: NbToastrService,
      ) {
          this.auto = 'auto';
        //   this.authService.onTokenChange()
        //     .subscribe((token: NbAuthJWTToken) => {
        //     this.user_id = token.getPayload()['id'];
        // });
        // <div ><img [src]="arr" height=":800px;" width="100%;" alt="Random first slide"></div>
    }


    close(){
        this.ref.close();
    }

   
    

    
}