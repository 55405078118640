import { ApplyComponent } from './apply.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {RecaptchaModule, RecaptchaFormsModule} from 'ng-recaptcha';
import { from } from 'rxjs';
import { MatSelectModule } from '@angular/material';
import { FileUploadModule } from 'primeng/fileupload';
import { FooterComponent } from '../footer/footer.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { NbCardModule, NbStepperModule,NbLayoutModule ,NbAccordionModule,NbInputModule,NbSelectModule, NbButtonModule, NbActionsModule, NbDatepickerModule, NbCheckboxModule,NbRadioModule,NbSpinnerModule } from '@nebular/theme';
//import { NbMomentDateModule } from '@nebular/moment';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { previewImg } from './dialog/transcriptpreview.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { EducationalFormComponent } from './dialog/educationalform.component';
import { NbAlertModule} from '@nebular/theme';
import { EducationalFormYearComponent } from './dialog/educationalformyearly.component';
import { addEducationalDetailsSemesterGPA } from './dialog/educationalformsemgpa.component';
import { addEducationalDetailsSemesterSGPI } from './dialog/educationalformsemsgpi.component';
import { addMasterEducationalDetailsSemesterSGPI } from './dialog/mastereducationalformsemsgpi.component';
import { EducationalFormBafComponent } from './dialog/educationalformbaf.component';
import { PaymentDialogComponent } from './dialog/paymentDialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule, MatInputModule, MatListModule } from '@angular/material';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
  declarations: [ApplyComponent,FooterComponent,NavbarComponent,previewImg,EducationalFormComponent,EducationalFormYearComponent,addEducationalDetailsSemesterGPA,addEducationalDetailsSemesterSGPI,addMasterEducationalDetailsSemesterSGPI,EducationalFormBafComponent,PaymentDialogComponent],
  imports: [
    CommonModule,
    NgxDocViewerModule,
    NbSpinnerModule,
    NbCardModule,
    NbStepperModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NbLayoutModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    NbAccordionModule,
    FileUploadModule,
    NbInputModule,
    NbSelectModule,
    NbDatepickerModule.forRoot(),
    // NbMomentDateModule,
    MatSelectModule,
    NbButtonModule,
    NbActionsModule,
    PdfViewerModule,
    NbCheckboxModule,
    NbRadioModule,
    NbAlertModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule
  ],
  entryComponents: [
    previewImg,
    EducationalFormComponent,
    EducationalFormYearComponent,
    addEducationalDetailsSemesterGPA,
    addEducationalDetailsSemesterSGPI,
    addMasterEducationalDetailsSemesterSGPI,
    EducationalFormBafComponent,
    PaymentDialogComponent
  ]
})
export class ApplyModule { }
