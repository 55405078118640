import {  Component ,Input } from '@angular/core';
import {MessageService} from 'primeng/api';
import { Router,ActivatedRoute} from '@angular/router';
import { UserService } from '../../@core/data/users.service';
import { ApiService } from '../../shared/api.service';
import { FormBuilder, FormGroup,Validators} from '@angular/forms';
import { NbDateService } from '@nebular/theme';
import { NbDialogService } from '@nebular/theme';
import {ResendEmailComponent} from './resend-email.component'
import { combineAll } from 'rxjs-compat/operator/combineAll';
// import { NbToastrService} from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbDialogRef,NbToastrService } from '@nebular/theme';

@Component({
  selector: 'nb-forgot-password',
  styleUrls: ['./forgot-password.component.scss'],
  templateUrl: './forgot-password.component.html',
  providers: [MessageService]
})
export class ForgotPasswordComponent{

  readonly emailValidate = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  ForgotPassForm: FormGroup;
	ForgotPassForm2: FormGroup;	
	alertFlag:any = 0;
	alertFlag1:any = 0;
	alertFlag2:any = 0;
	alertFlag3:any = 0;
	messagealertflag;
	param1 :Boolean = false;
	source: any;
	email :any;
	fatherName: any;
	motherName: any;
	address: any;
	pincode: any;
	city: any;
	district: any;
	state: any;
	fathersurname: any;
	mothersurname: any;
	inputprn: any;
	current_location: any;
	isTextFieldType: boolean;
	textFieldTypee: boolean;
	validation_messages: string;
	pwd_validation: boolean;
	// error: boolean;
  constructor(private ref: FormBuilder,
	public toasterService: NbToastrService,
	private router: Router,
	private route : ActivatedRoute,
    protected dateService: NbDateService<Date>,
    protected api : ApiService,
	private authService: NbAuthService,
		private dialogService: NbDialogService,
    )
     {  
    
    }

    ngOnInit() {
		var value = this.route.snapshot.queryParamMap.get('editvalue')
		this.source = this.route.snapshot.queryParamMap.get('source')
		this.email = this.route.snapshot.queryParamMap.get('email')
		this.fatherName = this.route.snapshot.queryParamMap.get('fatherName')
		this.motherName = this.route.snapshot.queryParamMap.get('motherName')
		this.address = this.route.snapshot.queryParamMap.get('address')
		this.pincode = this.route.snapshot.queryParamMap.get('pincode')
		this.city = this.route.snapshot.queryParamMap.get('city')
		this.district = this.route.snapshot.queryParamMap.get('district')
		this.state = this.route.snapshot.queryParamMap.get('state')
		this.fathersurname = this.route.snapshot.queryParamMap.get('fatherSurname')
		this.mothersurname = this.route.snapshot.queryParamMap.get('motherSurname')
		this.inputprn = this.route.snapshot.queryParamMap.get('inputprn')
		this.current_location = this.route.snapshot.queryParamMap.get('current_location')
		
		if(value == 'true'){
		this.param1 = true
			
		}else{

			this.param1 = false
		}
				this.ForgotPassForm = this.ref.group({
     		emailaddressCtrl:['',[Validators.required, Validators.pattern(this.emailValidate)]],
			reset_emailCtrl :[''],
			reset_pwdCtrl : [],
			reset_confirmpwdCtrl :[],

		});
		this.ForgotPassForm2 = this.ref.group({
			reset_emailCtrl :['',[Validators.required, Validators.pattern(this.emailValidate)]],
		   reset_pwdCtrl : [],
		   reset_confirmpwdCtrl :[],
	   });

    }

	// onKeyPress(event: any){console.log("onkeypress");
	
	// 	this.api.emailValues(this.ForgotPassForm.controls.emailaddressCtrl.value)
	// 	.subscribe( data =>{
	// 		if(data['status']==400){console.log("datasttsu",data['status']);
			
	// 			this.error=true
	// 			this.ForgotPassForm.controls.emailaddressCtrl.markAsDirty();
	// 		}
	// 	}
			
	// 	)
	// }
     
	checkpassword(duration,status){
		if(this.ForgotPassForm2.controls.reset_pwdCtrl.value != this.ForgotPassForm2.controls.reset_confirmpwdCtrl.value){		

			this.toasterService.show(       
				'Password doesnt match ! ! ', {duration},{status}
			  );
			this.validation_messages =  "Password doesn't match!";
			
			this.ForgotPassForm2.controls.reset_confirmpwdCtrl.markAsDirty();
			this.pwd_validation=false;
		}else{
			this.pwd_validation=true;
		}
	}

    onSubmit(){
		 var check_validation;
		 var requestemail = this.ForgotPassForm.controls.emailaddressCtrl.value ;
		 this.ForgotPassForm.controls.emailaddressCtrl.markAsDirty();
		 if(this.ForgotPassForm.valid){	
			check_validation = true;
			this.alertFlag = 0;
		 }else{
			check_validation = false;
			this.alertFlag = 1;
		 }
		 if(check_validation){

			this.api.ForgotPassword(requestemail)
			.subscribe(
					(data: any) => {
					if(data['status'] == 200){
							
							this.dialogService.open(ResendEmailComponent, {
							context: {
								email: requestemail,							
							},
					});

					}else if(data['status'] == 400){
						this.alertFlag1=1;
							this.messagealertflag =data['message'];                               

					}else{

					}       
							 err => console.error(err)
			});

		 }
    } 
	reset_password(){
		var check_validation;
	    var email =this.ForgotPassForm2.controls.reset_emailCtrl.value
		var pwd =this.ForgotPassForm2.controls.reset_pwdCtrl.value

		if(this.ForgotPassForm2.valid){	
			check_validation = true;
			this.alertFlag2 = 0;
		 }else{
			check_validation = false;
			this.alertFlag3 = 1;
		 }

		 if(check_validation&&this.pwd_validation)
		 {
			this.api.Reset_Password(email,pwd,this.source)
			.subscribe(
					(data: any) => {
					if(data['status'] == 200){
						this.toasterService.success('Password changed successfully!!','Success',{duration: 3000});		
						this.insert_data();

					}else if(data['status'] == 400){

				this.toasterService.success('Email doesnt exist!!','Error',{duration: 3000});                      

					}else{

					}       
							 err => console.error(err)
			});
		 }
	
	
		}

		insert_data(){
		// var email =this.ForgotPassForm2.controls.reset_emailCtrl.value
		var pwd =this.ForgotPassForm2.controls.reset_pwdCtrl.value
			this.api.confirmPass(this.email,pwd,this.source)
				.subscribe(
					(data : any) => {
						if(data['status'] == 200){

							//this.invalidPass = 0;
							this.api.insert_to_database(data['data'],this.source,this.fatherName,this.motherName,this.address,this.pincode,this.city,this.district,this.state,this.fathersurname,this.mothersurname,this.inputprn,this.current_location)
							.subscribe(
								(data1 : any) =>{
									if(data1['status'] == 200){
										//todo code for login directly
										// this.ref.close('done');
										//this.router.navigate(['/auth/login']);
										this.api.confirmPass(this.email,pwd,this.source)
										.subscribe(
											(data2 : any) =>{
												// this.ref.close();
												if(data2['status'] == 200){
													this.authService.authenticate('email', {email: 'abc@test.in', password: '123456'})					
													this.router.navigate(['/auth/login']);
												}else if(data2['status'] == 402){
												}
											}
										)
									}else if(data1['status'] == 400){
									}
								}
							)
						}else if(data['status'] == 400){
							// this.invalidPass = 1;
						}
					}
				)
		}

		showHidePassword(){
			this.isTextFieldType = !this.isTextFieldType;
		}

		showHideConfirmPassword(){
			this.textFieldTypee = !this.textFieldTypee;	
		}
}
