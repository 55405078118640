import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../shared/api.service';
import { NbToastrService, NbGlobalPhysicalPosition, NbStepperComponent,NbDialogService } from '@nebular/theme';
import { IfStmt } from '@angular/compiler';
import { ConfirmationService } from 'primeng/api';
import { MatStepper, MatSelectChange } from '@angular/material';
import { config } from '../../../config';
import { previewImg } from './dialog/transcriptpreview.component';
import { EducationalFormComponent } from './dialog/educationalform.component';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { EducationalFormYearComponent } from './dialog/educationalformyearly.component';
import { addEducationalDetailsSemesterGPA } from './dialog/educationalformsemgpa.component';
import { addEducationalDetailsSemesterSGPI } from './dialog/educationalformsemsgpi.component';
import { addMasterEducationalDetailsSemesterSGPI } from './dialog/mastereducationalformsemsgpi.component';
import { EducationalFormBafComponent } from './dialog/educationalformbaf.component';
import { MatRadioChange } from '@angular/material';
import { Observable, Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss'],
  providers: [ ConfirmationService]
})
export class ApplyComponent implements OnInit {
  
  checked:boolean=true;

  firstForm: FormGroup;
  captchaForm: FormGroup;
  secondForm: FormGroup;
  thirdForm: FormGroup;
  firstFormData: { };
  isHidden: boolean = true;
  userId='';
  firstname: any;
  lastname: any;
  fathername:any;
  mothername:any;
  gender: any;
  address: any;
  telno: any;
  mobile: any;
  email: any;
  dob:any;
  category:any;
  prnNumber:any;
  phyHand:any;
  prnUpload = false;
  
  //2nd step //
  user_id: any;
  transUploadUrl = config.docUploadUrl;
  index: number;
  position: string;
  status1: string;
  status: string;
  semestervalue: any;
  showNextButton = false;
  applicantFile = [];
  loadingdoc= false
  degreeValue: any;
  filteredOptions: Observable<string[]>;
  myControl = new FormControl('',Validators.required);
  college_data = [];
  course_data;
  hideUploadOneBachelor = true;
  firstclgID: any;
  //end of 2nd step//

  //Payment tab start//
  applicant_id: any;
  user_data: any;
  accessCode: any;
  secureUrl: any;
  encRequest: any;
  //Payment tab end//

  @ViewChild('stepper') stepper: NbStepperComponent;
  setdate: string;
  showNextButtonEdu = false;
  educatonalData = [];

  pattern_show = false;
  course_system_show = false;
  Show1 = false;
  Show2 = false;
  Show3 = false;
  Show4 = false;
  Show5 = false;
  Show6 = false;

  emobileno;
  etelno;
  newaddress;

  constructor(private fb: FormBuilder,protected api : ApiService,private toasterService: NbToastrService,
    private confirmationService: ConfirmationService,
    private dialogService: NbDialogService) {
  }

  ngOnInit() {
    this.firstForm = this.fb.group({
      // stud_surnameCtrl: [''],
      // stud_fnameCtrl: [''],
      // stud_fathernameCtrl: [''],
      // stud_mothernameCtrl: [''],
      // studDOBCtrl: [''],
      // genderCtrl: [''],
      // addressCtrl: [''],
      // mobileCtrl: [''],
      // emailidCtrl: [''],
      // telnoCtrl: [''],
      // categoryCtrl: [''],
      // prnCtrl : [''],
      // phyHandCtrl : [''],
      stud_surnameCtrl: ['', Validators.required],
      stud_fnameCtrl: ['', Validators.required],
      stud_fathernameCtrl: ['', Validators.required],
      stud_mothernameCtrl: ['', Validators.required],
      studDOBCtrl: ['', Validators.required],
      genderCtrl: ['', Validators.required],
      addressCtrl: ['', Validators.required],
      mobileCtrl: ['', Validators.required],
      emailidCtrl: ['', Validators.required],
      telnoCtrl: [''],
      categoryCtrl: ['', Validators.required],
      prnCtrl : [''],
      phyHandCtrl : ['', Validators.required],
    });

    this.captchaForm = this.fb.group({
     captchaCtrl:['', Validators.required]
    });
        
    this.secondForm = this.fb.group({
      secondCtrl: ['', Validators.required],
      course_type : [''],
      pattern_course : [''],
      course_system : [''],
    });

    this.thirdForm = this.fb.group({
      fNPaymentCtrl : [''],
      mobilePaymentCtrl : [''],
      emailPaymentCtrl : [''],
      amountPaymentCtrl : ['']
    });

    this.api.getCousesColleges()
      .subscribe(
        (data: any) => { 
          if(data['status'] == 200){
            this.course_data = data['data'];
            data['colleges'].forEach((element)=>{
              this.college_data.push( element ) 
            })
            //this.college_data = data['colleges'];
          }else{

          }
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

  }

  private _filter(value: string){
    console.log("value--_filter---->"+value);
    if(value == ''){
      this.hideUploadOneBachelor = true 
    }
    const filterValue = this._normalizeValue(value);
  
    return this.college_data.filter(option => this._normalizeValue(option.name).includes(filterValue)); 
  }
  
  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  selectCollegeBachelor(college , value, name){
    console.log("name---->"+name, college);
    this.firstclgID = college;
    if(college!=null || college != undefined || college!='' || college!='undefined'){
      this.hideUploadOneBachelor = false 
    }else{
      this.hideUploadOneBachelor = true 
    }
    // this.edu_form.patchValue({
    //   collegeNameCtrl :name,
    // })
  }

  toggle(checked: boolean) {
    this.checked = !checked;
  }


  firstFormSubmit(variable){
    if(this.firstForm.controls.prnCtrl.value == undefined || this.firstForm.controls.prnCtrl.value == null || this.firstForm.controls.prnCtrl.value == ''){
      this.prnUpload = true;
    }else{
      this.prnUpload = false;
    }
    const config = {
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT
    };
    this.firstForm.controls.stud_surnameCtrl.markAsDirty();
    this.firstForm.controls.stud_fnameCtrl.markAsDirty();
    this.firstForm.controls.stud_fathernameCtrl.markAsDirty();
    this.firstForm.controls.stud_mothernameCtrl.markAsDirty();
    this.firstForm.controls.studDOBCtrl.markAsDirty();
    this.firstForm.controls.genderCtrl.markAsDirty();
    this.firstForm.controls.addressCtrl.markAsDirty();
    this.firstForm.controls.mobileCtrl.markAsDirty();
    this.firstForm.controls.emailidCtrl.markAsDirty();
    this.firstForm.controls.categoryCtrl.markAsDirty();
    this.firstForm.controls.phyHandCtrl.markAsDirty();

    if (this.firstForm.valid) {
      var date = this.firstForm.controls.studDOBCtrl.value;
      var datePipe = new DatePipe("en-IN");
      this.setdate = datePipe.transform(date, 'yyyy-MM-dd');
      this.firstFormData = {
      stud_surnameCtrl: this.firstForm.controls.stud_surnameCtrl.value,
      stud_fnameCtrl:this.firstForm.controls.stud_fnameCtrl.value,
      stud_fathernameCtrl:this.firstForm.controls.stud_fathernameCtrl.value,
      stud_mothernameCtrl:this.firstForm.controls.stud_mothernameCtrl.value,
      studDOBCtrl:this.setdate,
      genderCtrl:this.firstForm.controls.genderCtrl.value,
      addressCtrl:this.firstForm.controls.addressCtrl.value,
      mobileCtrl:this.firstForm.controls.mobileCtrl.value,
      emailidCtrl:this.firstForm.controls.emailidCtrl.value,
      telnoCtrl:this.firstForm.controls.telnoCtrl.value,
      categoryCtrl:this.firstForm.controls.categoryCtrl.value,
      prnCtrl:this.firstForm.controls.prnCtrl.value,
      phyHandCtrl:this.firstForm.controls.phyHandCtrl.value,
    }

    if(this.user_id==undefined || this.user_id==''){
      this.api.addFirstFormData(this.firstFormData).subscribe(data => {
        if(data['status']==200){
          this.toasterService.show('Successfully saved!!!...', 'Success', config);
          this.user_id = data['data']['id'];
          console.log('this.user_id------>'+this.user_id);
          this.setThirdFormValue(this.user_id);
          this.userId = data['data']['id'];
          this.firstname = data['data']['firstname'];
          this.lastname = data['data']['lastname'];
          this.fathername = data['data']['fathername'];
          this.mothername = data['data']['mothername'];
          this.gender = data['data']['gender'];
          this.address = data['data']['address'];
          this.telno = data['data']['telno'];
          this.mobile = data['data']['mobile'];
          this.email = data['data']['email'];
          this.stepper.next();
        }
      });
    }else if(this.user_id!=undefined || this.user_id!=''){
      this.api.updateFirstformData(this.firstFormData,this.user_id,variable).subscribe(data=>{
        if(data['status']==200){
          this.setThirdFormValue(this.user_id);
          this.toasterService.show('Successfully updated!!!...', 'Success', config);
          this.stepper.next();
        }
      });
    }
  }else{
    this.toasterService.show('Please fill mandatory details!!!...', 'Warning', config);
  }
} 

  onFirstSubmit() {
    this.firstForm.markAsDirty();
  }

  onSecondSubmit() {
    this.secondForm.markAsDirty();
  }

  getData(event: MatSelectChange){
    this.semestervalue = event.source.value
  }

  getDegree(event: MatSelectChange){
    this.degreeValue = event.source.value
    //console.log("this.degreeValue== "+this.degreeValue)
  }

  onUpload(event: any) {
    const reader = new FileReader();
    var duration = 10000;
    this.index += 1;
    this.position = 'top-right';
    this.status1 = 'success';
    const config = {
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT
    };
    //this.ngOnInit();
    if (event.files && event.files.length) {
      const [file] = event.files;
      reader.readAsDataURL(file);
      var json = JSON.parse(event.xhr.response);
      var yourData = json.Data; // or json["Data"]
      var yourStatus = json.status; // or json["Data"]
      var yourMessage = json.message; // or json["Data"]

      if (yourStatus == 200) {
        this.loadingdoc = false;
        this.toasterService.show(yourMessage, 'Success', config);
        this.api.getApplicantFiles(this.user_id).subscribe(data => {
          this.applicantFile = data['data']
          // console.log("data== "+JSON.stringify(data))
          // console.log("length== "+data['data'].length)
          if(data['data'].length >= 2){
            this.showNextButton = true
          }else{
            this.showNextButton = false
          }
        })
      } else if (yourStatus == 401) {
       // this.loading = false;
       this.loadingdoc = false;
       this.toasterService.show(yourMessage, 'Success', config);
      } else if (yourStatus == 400) {
       // this.loading = false;
       this.loadingdoc = false;
       this.toasterService.show(yourMessage, 'Success', config);
      }
    }
  }

  onSelect($event: any,value): void {
    var maxFileSize =  5000000;
    var imgArr = $event.files[0].name.split('.');
    var extension = imgArr[imgArr.length - 1].trim();
    this.loadingdoc = true;
    if ($event.files[0].size > maxFileSize) {
      this.confirmationService.confirm({
        message: 'Maximum file size should be 5 MB.',
        header: 'Invalid File Size',
        icon: 'pi pi-info-circle',
        rejectVisible : false,
        acceptLabel: 'Ok'
      });
      this.loadingdoc = false;
    }

    if(value!=undefined && (extension!='jpg' && extension!='jpeg' && extension!='png' && extension!='JPG' && extension!='JPEG' && extension!='PNG' && extension!='pdf' && extension!='Pdf' && extension!='docx' && extension!='DOCX') ) {
      this.confirmationService.confirm({
        message: 'Please upload your transcript in .jpeg or .jpg or .png or .docx formats',
        header: 'Invalid File Type',
        icon: 'pi pi-info-circle',
        rejectVisible : false,
        acceptLabel: 'Ok'
      });
      this.loadingdoc = false;
    }

    if(value==undefined && (extension!='jpg' && extension!='jpeg' && extension!='png' && extension!='pdf' && extension!='JPG' && extension!='JPEG' && extension!='PNG' && extension!='PDF' && extension!='docx' && extension!='DOCX')){
      this.confirmationService.confirm({
        message: 'Please upload your transcript in .jpeg or .jpg or .png or .pdf or .docx formats',
        header: 'Invalid File Type',
        icon: 'pi pi-info-circle',
        rejectVisible : false,
        acceptLabel: 'Ok'
      });
      this.loadingdoc = false;
    }
  }

  onView(imagename,imgType){
    console.log("imgType== "+imgType)
    this.dialogService.open(previewImg, {
      context: {
      arr : imagename,
      //arr : 'https://migration@hsncu.edu.in/uiserver/upload/transcript/20086/HywOaKlgI.pdf',
      imgType : imgType
      },
    });
  }

  // onDeleteMarklist(info){
  //   var duration = 10000;
  //   this.position = 'top-right';
  //   this.status = 'success';
  //   this.api.deleteApplicantFile(info).subscribe(data => {
  //     var yourMessage = data['message']
  //     if(data['status'] == 200){
  //       this.status = 'success';
  //       const config = {
  //         position: NbGlobalPhysicalPosition.TOP_RIGHT
  //       };
  //       this.toasterService.show(" "+yourMessage,`Success`,config)
  //       this.api.getApplicantFiles(this.user_id).subscribe(data => {
  //         this.applicantFile = data['data']
  //         if(data['data'].length >= 2){
  //           this.showNextButton = true
  //         }else{
  //           this.showNextButton = false
  //         }
  //       })
  //     }
  //   })
  // }


  downldTemplate(){
    this.api.downloadTemplate().subscribe(data => {
			saveAs(data);    

    })
  }

  onThirdSubmit() {
    this.thirdForm.markAsDirty();
  }
  async resolved(captchaResponse: string) {
    console.log(`Resolved response token: ${captchaResponse}`);
    await this.sendTokenToBackend(captchaResponse); 
  }
  //function to send the token to the node server
sendTokenToBackend(tok){
  //calling the service and passing the token to the service
  this.api.sendToken(tok).subscribe(
    data => {
      if(data.success)
      {
        this.isHidden = false;
        console.log("this.isHidden==1===>"+this.isHidden+" user_id-------->"+this.user_id);
      }
      else
      {
      this.isHidden = true;
      console.log("this.isHidden==2===>"+this.isHidden)
      }
    },
    err => {
      this.isHidden = true;
      console.log("this.isHidden==3===>"+this.isHidden)
      console.log(err)
    },
    () => {}
  );
}
  getCaptchactrl(){
    // console.log("3333")
    return this.captchaForm.get('captchaCtrl').hasError('required') ?'Captcha is required ' : '';
  }

  setThirdFormValue(applicant_id){
    //console.log('applicant_id---->'+applicant_id);
    this.api.getApplicantData(applicant_id).subscribe(data => {
      //console.log("data['data']---->"+JSON.stringify(data['data']));
      if(data['status']==200){
        this.thirdForm.patchValue({fNPaymentCtrl :  data['data']['user_name']})
        this.thirdForm.patchValue({mobilePaymentCtrl : data['data']['user_mobile']})
        this.thirdForm.patchValue({emailPaymentCtrl : data['data']['user_email'] })
        this.thirdForm.patchValue({amountPaymentCtrl :  data['data']['amount']})
      }
    });
  }

  async firstpayment(){
    var user_id = this.user_id;
    var payment = await this.api.paymentrequest(user_id, this.thirdForm.controls.amountPaymentCtrl.value,this.thirdForm.controls.emailPaymentCtrl);
    payment.subscribe(
        data => {
          this.accessCode = data['data']['accessCode'];
          this.secureUrl = data['data']['secureUrl'];
          this.encRequest = data['data']['encRequest'];
          setTimeout(function(){ 
           this.loading = false;
            var myForm = <HTMLFormElement>document.getElementById('nonseamless');
            myForm.submit();
          }, 1000);
        },
        error => {
            console.log("Error", error);
        }
    ); 
  }

  next(){
    this.stepper.next();
  }

  addEducationalDetails(id,user_id){
    var paid_amount = 'false';
    var admin_show = 'false';
      this.dialogService.open(EducationalFormComponent, {
        closeOnBackdropClick : false,
        context: {
          user_id : user_id,
          edu_id : id,
          amount_paid : paid_amount,
          admin_show : admin_show
        },
      }).onClose
      .subscribe(
        (data: any) => {
          if(data){
            this.position = 'top-right';
            this.status1 = 'success';
            const config = {
              position: NbGlobalPhysicalPosition.BOTTOM_RIGHT
            };
            var yourMessage = 'Saved';
            this.toasterService.show(yourMessage, 'Success', config);
          }
          this.api.getApplicantEducationalValues(user_id).subscribe(data => {
            this.educatonalData = data['data']
            if(data['data'].length >= 1){
              this.showNextButtonEdu = true
            }else{
              this.showNextButtonEdu = false
            }
          })
          
        }
      )
    }

  onDeleteEducation(info){
    var duration = 10000;
    this.position = 'top-right';
    this.status = 'success';
    this.api.deleteEducation(info).subscribe(data => {
      var yourMessage = data['message']
      if(data['status'] == 200){
        this.status = 'success';
        const config = {
          position: NbGlobalPhysicalPosition.TOP_RIGHT
        };
        this.toasterService.show(" "+yourMessage,`Success`,config)
        this.api.getApplicantEducationalValues(this.user_id).subscribe(data => {
          this.educatonalData = data['data']
          if(data['data'].length >= 1){
            this.showNextButtonEdu = true
          }else{
            this.showNextButtonEdu = false
          }
        })
      }
    })
  }
}
