import { Component , Input, ChangeDetectionStrategy} from '@angular/core';
import { NbDialogRef,NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/api.service';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

@Component({
   selector: 'nb-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
   template: `<nb-card [style.width.px]="450"  [style.height.px]="300" status="success">
      <nb-card-header>
        <div class="row">
          <div class="col-md-8">
            Please Enter the message for student:
          </div>
          <div class="col-md-4" style="text-align:right">
            <nb-action icon="ion-close" (click)="close()"></nb-action>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <form [formGroup]="statementForm"  class="step-container">
        <textarea cols="30" rows="5"  [ngClass]="{'form-control-danger': statementForm.controls.statementCtrl.invalid && (statementForm.controls.statementCtrl.dirty || statementForm.controls.statementCtrl.touched)}" formControlName="statementCtrl" placeholder="Please enter the message" class="form-control">
        </textarea>
        <div *ngIf="form_submitted && statementForm.controls.statementCtrl.invalid">
          <div *ngIf="statementForm.controls.statementCtrl.required">Message is required</div>
        </div>
        </form>
      </nb-card-body>
      <nb-card-footer>
        <div class="row" >
          <div class="col-md-5"></div>
          <div class="col-md-7"><button nbButton hero status="primary" (click)="ok()">OK</button></div>
        </div>           
      </nb-card-footer>
    </nb-card>
    `,
})

export class StatementComponent  {
  @Input() userId:any;
  @Input() doc_id:any;
  @Input() errata_data:any;
  @Input() app_Id:any;
  statementForm : FormGroup;
  form_submitted: boolean=false;
  title;

  constructor(protected ref: NbDialogRef<StatementComponent>,
    protected api : ApiService,
    private fb: FormBuilder,
    private toastrService: NbToastrService,) {
  }

  ngOnInit() { 
    this.buildstatementForm();
  }

  private buildstatementForm() : void{
    this.statementForm = this.fb.group({
      statementCtrl :['',[Validators.required]],
    });
  }

  ok(){    
    this.statementForm.controls.statementCtrl.markAsDirty();         
    this.form_submitted = true;
    if (this.statementForm.valid) {
      var msg_from_admin_data ={
        app_id:this.app_Id,
        userId : this.userId,
        message: this.statementForm.controls.statementCtrl.value,
        doc_id : this.doc_id,
        errata_data : this.errata_data
      }
      //console.log("msg_from_admin_data=====>"+JSON.stringify(msg_from_admin_data));
      this.api.sendmessage(msg_from_admin_data) .subscribe(
        (data: any) => {
          if(data['status'] == 200){
            this.ref.close(data);
            this.toastrService.show(`Notification successfully sent`,[this.title="Success"]);
          }else if(data['status'] == 400){ 
            this.ref.close();
            this.toastrService.show(`Error in sending notification.`,[this.title="Error"]);                       
          }else{
            this.ref.close();
          }       
          err => console.error(err)
      });
    }else{
      this.toastrService.show(       
        `Please Enter the message`,[this.title="Warning"]
          );  
    }   
  }

  close(){
    this.ref.close();
  }
}