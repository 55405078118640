import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from './../../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { FormBuilder, FormGroup , Validators, FormControl} from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { UserService } from './../../@core/data/users.service';
import { Observable, Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

import * as _moment from 'moment';
import { Moment} from 'moment';

import {ViewChildren, QueryList} from '@angular/core';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'educational',
  template: `
  <style>
  .mat-form-field--inline {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .mat-form-field--inline .mat-form-field {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
    }

    .mat-form-field--inline .mat-form-field:nth-child(1) {
        margin-right: 10px;
    }
    
    @media (max-width: 767px) {
      #selectLine{  
        width: 65%;
      } 
    }
      
    @media (min-width: 1200px) {
      #selectLine{  
        width: 145%;
      } 
    }
      
    @media (min-width: 768px) {
      #selectLine{  
        width: 65%;
      } 

      .offset-md-3 {
        margin-left: 0%;
      }

      .col-md-6 {
          max-width: 100%;
      }

      #roll_no {
        left: 50px;
      }

      #semester_css{
        padding-left:45px;
      }
    }
      
    @media (min-width: 992px) {
      #selectLine{  
        width: 145%;
      } 

      #roll_no {
        left: 50px;
      }

      #semester_css{
        padding-left:45px;
      }
    }

    .example-full-width {
      width: 100%;
    }
  </style>
  <nb-card status="success" class="col-md-6 offset-md-3" [style.height.px]="600">
    <nb-card-header>Add Your Last Appeared Exam Details</nb-card-header>
    <nb-card-body>
      <form [formGroup]="edu_form"  class="step-container"  [nbSpinner]="loading">
        <div class="row">
          <div class="col-xl-3">College Name : </div>
          <div class="col-xl-9">
            <div *ngIf="collegenameshow == false">
              <form class="example-form">
                <mat-form-field class="example-full-width">
                <input type="text" (focus)="onFocus(0)"
                    placeholder="Select College"
                    aria-label="Number"
                    matInput
                    [formControl]="myControl"
                    [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete">
                      <mat-option (onSelectionChange)="selectCollegeBachelor($event, option.id ,'1' , option.college_name, option.college_address)" *ngFor="let option of filteredOptions | async" [value]="option.college_name | uppercase">
                      {{option.college_name | uppercase}}
                      </mat-option>
                  </mat-autocomplete>
                  </mat-form-field>
              </form>
              </div>
              <div *ngIf="collegenameshow == true">
                <input  formControlName="collegeNameCtrl" nbInput type="text"  readonly class="form-control">
                <a>
                  <i title="Edit College Name" class="fas fa-pen-fancy" (click)="editCollegeName()"></i>
                </a> 
              </div> 
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">College Address : </div>
          <div class="col-md-9"> <input  ngModel="{{edu_data?.CollegeAddress}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.collegeAddressCtrl.invalid && (edu_form.controls.collegeAddressCtrl.dirty || edu_form.controls.collegeAddressCtrl.touched)}" formControlName="collegeAddressCtrl" placeholder="Address" id="" class="form-control" readonly> </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-3">Course Name : </div>
          <div class="col-md-9"> 
            <div *ngIf="coursenameshow == false">
              <form class="example-form">
                <mat-form-field class="example-full-width">
                <input type="text" (focus)="onFocus(1)"
                    placeholder="Select Course Name"
                    aria-label="Number"
                    matInput
                    [formControl]="myControlCourse"
                    [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete">
                      <mat-option style="font-size:12px;" (onSelectionChange)="selectCourse($event, option.id ,'1' , option.course_name, option.duration)" *ngFor="let option of filteredOptionCourses | async" [value]="option.course_name | uppercase">
                      {{option.course_name | uppercase}}
                      </mat-option>
                  </mat-autocomplete>
                  </mat-form-field>
              </form>
            </div>
              <input *ngIf="coursenameshow == true" formControlName="courseCtrl" nbInput type="text"  readonly class="form-control">
              <a>
                <i title="Edit Course Name" class="fas fa-pen-fancy" (click)="editCourseName()"></i>
              </a>
          </div>
        </div>
        <br>
        <!-- <div class="row">
          <div class="col-md-3">Part Name : </div>
          <div class="col-md-9"> <input  ngModel="{{edu_data?.PartName}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.partNameCtrl.invalid && (edu_form.controls.partNameCtrl.dirty || edu_form.controls.partNameCtrl.touched)}" formControlName="partNameCtrl" placeholder="Part Name" id="" class="form-control" readonly> </div>
        </div> -->
        <div class="row">
          <div class="col-md-3">Examination Pattern type : </div>
          <div class="col-md-3">
          <mat-form-field >
          <mat-select [(value)]="pattern" name="pattern" formControlName="collegePatternCtrl" (selectionChange)="getData($event)">
              <mat-option [value]="'semester'">SEMESTER</mat-option>
              <mat-option [value]="'annual'">ANNUAL</mat-option>
          </mat-select>
          </mat-form-field>
        <!--  <span *ngIf="ptternValidation===false" style="color:red;">Please select Pattern type</span> -->
          </div>
          <div class="col-md-3"  *ngIf="pattern=='annual'">
            Last attended year: </div>
            <div class="col-md-3"  *ngIf="pattern=='annual'">
            <mat-form-field >
              <mat-select [(ngModel)]="pattern_year" (selectionChange)="onSelectPattern_year($event)" formControlName="collegeYearPatternCtrl">
              <mat-option  *ngFor = "let pattern_year of pattern_data_year" [value]="pattern_year" >{{pattern_year | uppercase}}</mat-option>
              </mat-select>
            </mat-form-field>
            <!--<span *ngIf="yearValidation===false" style="color:red;">Please select College Year.</span>-->
        </div>
        <div class="col-md-3" *ngIf="pattern=='semester'">
            Last Attended Semester:   
            </div>
            <div class="col-md-3" *ngIf="pattern=='semester'">
            <mat-form-field>
              <mat-select [(ngModel)]="pattern_semester" (selectionChange)="onSelectPattern_sem($event)" formControlName="collegeSemPatternCtrl">
              <mat-option  *ngFor = "let pattern_semester of pattern_data_sem" [value]="pattern_semester" >{{pattern_semester | uppercase}}</mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="semesterValidation===false" style="color:red;">Please select Semester.</span> 
    </div> 
          <!-- <div class="col-md-9"> <input ngModel="{{edu_data?.PartName}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.partNameCtrl.invalid && (edu_form.controls.partNameCtrl.dirty || edu_form.controls.partNameCtrl.touched)}" formControlName="partNameCtrl" placeholder="Part Name" id="" class="form-control" readonly> </div> -->
        </div>
        <br>
        <!-- <div class="row">
        <div class="col-md-3">Last attended year : </div>
          <div class="col-md-2">
            <mat-form-field id="selectLine" >
              <mat-select ngModel="{{edu_data?.CollegeYear}}" name="collegeYear" formControlName="collegeYearCtrl" disabled>
                  <mat-option value="First">First</mat-option>
                  <mat-option value="Second">Second</mat-option>
                  <mat-option value="Third">Third</mat-option>
                  <mat-option value="Fourth">Fourth</mat-option>
                  <mat-option value="Fifth">Fifth</mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="collegeYearValidation===false" style="color:red;">Please select College Year.</span> 
          </div>
          <div class="col-md-3" id="semester_css">Semester : </div>
          <div class="col-md-3"> <input  ngModel="{{edu_data?.Semester}}" nbInput type="text" [ngClass]="{'form-control-danger': edu_form.controls.semesterCtrl.invalid && (edu_form.controls.semesterCtrl.dirty || edu_form.controls.semesterCtrl.touched)}" formControlName="semesterCtrl" placeholder="Term Name" id="" class="form-control" readonly> </div>
        </div> -->
      <br>
      <div class="row">
        <div class="col-md-3">Passing Month & Year :</div>
        <div class="col-md-3">
          <mat-form-field appearance="fill">
            <mat-label>Month and Year</mat-label>
            <input matInput [matDatepicker]="dp" [max]="maxDate" [formControl]="date" (focus)="dp.open()" readonly>
            <mat-datepicker-toggle matSuffix [for]="dp" (focus)="dp.open()"></mat-datepicker-toggle>
            <mat-datepicker #dp  startView="multi-year" (yearSelected)="chosenYearHandler($event)"
              (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>
          <span *ngIf="monthYearValidation===false" style="color:red;">Please select month and year.</span>
        </div>
        <div class="col-md-3" id="roll_no">Roll No/Seat No :</div>
        <div class="col-md-3">
          <input  ngModel="{{edu_data?.rollNo}}" nbInput type="text" pattern="[A-Za-z0-9]+" 
          (keyup)="OnlyNoCharAllowed($event)" [ngClass]="{'form-control-danger': edu_form.controls.rollNoCtrl.invalid && (edu_form.controls.rollNoCtrl.dirty || edu_form.controls.rollNoCtrl.touched)}" formControlName="rollNoCtrl" placeholder="Roll No" id="" class="form-control"> 
         <!-- <span *ngIf="rollNOValidation===false" style="color:red;">Only 1 to 20 alphanumeric seat or roll no are allowed.</span> -->
        </div>
      </div>
      </form>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton hero status="primary" (click)="dismiss()">Close</button> 
        <button *ngIf="amount_paid == 'false'" nbButton status="info" (click)="saveEdu()">save</button>
    </nb-card-footer>
</nb-card>
  `,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class EducationalFormComponent implements OnInit {
  @ViewChildren(MatAutocompleteTrigger) autoComplete: QueryList<MatAutocompleteTrigger>;
  FeedbackForm : FormGroup;
  @Input() collegeAddress: any;
  @Input() collegeName: any;
  @Input() user_id: any;
  @Input() edu_id: any;
  @Input() source: any;
  @Input() course: any;
  //@Input() course_name:string;
  @Input() amount_paid:string;
  @Input() admin_show:string;
  validations_flag = false;
  feedback_message ;
  showExperience = false;

  edu_form: FormGroup;
  filteredOptions: Observable<string[]>;
  filteredOptionCourses: Observable<string[]>;
  myControl = new FormControl('',Validators.required);
  myControlCourse = new FormControl('',Validators.required);
  years_array;
  courseValidation = true;
  collegeValidation = true;
  collegeYearValidation = true;
  semesterValidation  = true;
  monthValidation  = true;
  yearValidation = true;
  rollNOValidation = true;
  monthYearValidation = true;
  edu_data;
  course_data = [];
  college_data = [];
  user: any;
  collegenameshow = false;
  coursenameshow = false;
  collegename;
  college_address: any;
  college_info: any;
  loading: boolean;
  date = new FormControl(null);
  pattern_data_year = ['First Year', 'Second Year', 'Third Year', 'Fourth Year', 'Fifth Year','Sixth Year'];
  pattern_data_sem = ['Sem I', 'Sem II', 'Sem III', 'Sem IV', 'Sem V', 'Sem VI', 'Sem VII', 'Sem VIII', 'Sem IX', 'Sem X', 'Sem XI', 'Sem XII'];
  
  readonly numberpattern = /^[-+]?[0-9]+$/ ;
  readonly letterpattern =/^[A-Za-z]+$/;
  pattern: any;
  pattern_annual: boolean;
  pattern_semester: any;
  pattern_year: any;
  academicYear: string;
  duration: any;
  degree_type: any;
  maxDate = new Date();
  constructor(protected api : ApiService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    protected ref: NbDialogRef<EducationalFormComponent>) {
     }

  async ngOnInit() {
    this.getStudentEducation(this.user_id);
    //console.log('user_id',this.user_id)
    this.edu_form = this.formBuilder.group({
      collegeNameCtrl : [ '' ,  Validators.required],
      collegeAddressCtrl : [ '',  Validators.required ],
      courseCtrl: [ '', Validators.required ],
      collegeYearPatternCtrl : [''],
      collegeSemPatternCtrl : [''],
      monthYearCtrl : [ '' ,  Validators.required],
      rollNoCtrl : [ '' ,  [Validators.required,Validators.minLength(1),Validators.maxLength(20)]],
      collegePatternCtrl : ['',Validators.required]
      //overAllGradeCtrl : [ '' ,  Validators.required],
    })

    // if(this.course_name!=''){
    //   this.coursenameshow = true;
    //   this.edu_form.patchValue({
    //     courseNameCtrl : this.course_name,
    //   })
    // }
    
    this.api.getColleges()
      .subscribe(
        (data: any) => { 
          if(data['status'] == 200){
            data['colleges'].forEach((element)=>{
              this.college_data.push( element ) 
            })
          }else{

          }
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    
    this.filteredOptionCourses = this.myControlCourse.valueChanges.pipe(
      startWith(''),
      map(value => this._filterCourse(value))
    );


    const now = new Date().getUTCFullYear();    
    this.years_array = Array(now - (now - 50)).fill('').map((v, idx) => now - idx);
    if(this.user_id != null){
      this.api.getEducation(this.edu_id,this.user_id)
      .subscribe(
        (data: any) => { 
          if(data['status'] == 200){
            this.edu_data = data['data'];
           //console.log("this.edu_data---->"+JSON.stringify(this.edu_data));
            this.date = new FormControl(moment(this.edu_data.Month));
            this.edu_form.patchValue({
              monthYearCtrl :this.date.value,
            })
            if(data['data']['CollegeName']){
              this.edu_form.controls.collegeNameCtrl.setValidators([]);
              this.edu_form.controls.collegeNameCtrl.updateValueAndValidity();
              this.collegenameshow = true;
              this.edu_form.patchValue({
                collegeNameCtrl :data['data']['CollegeName'].toUpperCase(),
              })
            }
            if(data['data']['CourseName']){
              this.edu_form.controls.courseCtrl.setValidators([]);
              this.edu_form.controls.courseCtrl.updateValueAndValidity();
              this.coursenameshow = true;
              this.edu_form.patchValue({
                courseCtrl :data['data']['CourseName'].toUpperCase(),
              })
            }
            if(data['data']['pattern']){
              this.edu_form.controls.collegePatternCtrl.setValidators([]);
              this.edu_form.controls.collegePatternCtrl.updateValueAndValidity();
              this.pattern = data['data']['pattern']
            }
            if(data['data']['duration']){
            this.duration = data['data']['duration'];
            var duration_year = this.duration;
            var duratio_sem = 2*this.duration;
            this.pattern_data_year = this.pattern_data_year.splice(0,duration_year);//console.log("pattern_year",this.pattern_data_year);
            this.pattern_data_sem = this.pattern_data_sem.splice(0,duratio_sem);//console.log("pattern_sem",this.pattern_data_sem);
            }
            
            if(data['data']['Semester']){
              this.edu_form.controls.collegeSemPatternCtrl.setValidators([]);
              this.edu_form.controls.collegeSemPatternCtrl.updateValueAndValidity();
              this.pattern_semester = data['data']['Semester'];//console.log("this.paternsemester",this.pattern_semester);
            }
            if(data['data']['CollegeYear']){
              this.edu_form.controls.collegeYearPatternCtrl.setValidators([]);
              this.edu_form.controls.collegeYearPatternCtrl.updateValueAndValidity();
              this.pattern_year = data['data']['CollegeYear'];//console.log("this.paternsYear",this.pattern_year);
            }
          }else{
            console.log("NO Data---->");
          }
      });
    }
  }

  private _filter(value: string){
    const filterValue = this._normalizeValue(value);
  
    return this.college_data.filter(option => this._normalizeValue(option.college_short_form).includes(filterValue)); 
  }

  private _filterCourse(value: string){
    const filterValue = this._normalizeValue(value);
  
    return this.course_data.filter(option => this._normalizeValue(option.course_short_form).includes(filterValue)); 
  }
  
  private _normalizeValue(value: string): string {
    if(value){
      return value.toLowerCase().replace(/\s/g, '');
    }else{
      return '';
    }
  }

  getStudentEducation(user_id){ 
this.loading = true
    this.api.getStudentCollegeData(this.user_id).subscribe(data => {
      this.loading = false;
      if(data['status']==200){
        this.college_info = data['data'];
        console.log("collegeinfo",JSON.stringify(this.college_info))
        if(this.college_info.CollegeName !=null){
          this.edu_form.controls.collegeNameCtrl.setValidators([]);
          this.edu_form.controls.collegeNameCtrl.updateValueAndValidity();
          this.collegenameshow = true;
          if(this.college_info.CourseName == null || this.college_info.CourseName == 'null'){
          this.coursenameshow = false;
          }else{
          this.coursenameshow = true;
          }
          this.edu_form.patchValue({
           collegeNameCtrl:  this.college_info.CollegeName
          })
          this.date = new FormControl(moment(this.college_info.Year));
         // console.log("data",this.date,"this.data.value",this.date.value)
          this.edu_form.patchValue({
            monthYearCtrl :this.date.value,
          })
          console.log("course",this.course)
           this.api.getCourses(this.course,this.college_info.CollegeName)
                  .subscribe(
                (data: any) => {console.log("dta",data) 
                if(data['status'] == 200){console.log("200",data) 
                this.course_data = [];
                data['data'].forEach((element)=>{
            this.course_data.push( element ) ;
          })
        }else{

        }
    });
            // console.log('this.college_info.CollegeName.vcalui',this.edu_form.controls.collegeNameCtrl.value)
        }
        if(this.college_info.CollegeAddress !=null){console.log("CollegeAddress")
        console.log("collegeinfodf",JSON.stringify(this.college_info))
          this.edu_form.controls.collegeAddressCtrl.setValidators([]);
          this.edu_form.controls.collegeAddressCtrl.updateValueAndValidity();
        //  this.edu_form.controls.collegeAddressCtrl.setValue(this.college_info.collegeName)
        this.collegenameshow = true;
        if(this.college_info.CourseName == null){
          this.coursenameshow = false;
        }else{
          this.coursenameshow = true;
        }
      this.edu_form.patchValue({
        collegeAddressCtrl : this.college_info.CollegeAddress.toUpperCase()
    })
  }
      }else if(data['status']==401){
        this.loading = false
      }
    })
    
  }

  saveEdu() {
    // this.edu_form.controls.rollNoCtrl.setValidators([ Validators.required, Validators.pattern(this.numberpattern)]);
    // this.edu_form.controls.rollNoCtrl.updateValueAndValidity();
    //this.edu_form.controls.overAllGradeCtrl.setValidators([ Validators.required, Validators.pattern(this.letterpattern)]);
    //this.edu_form.controls.overAllGradeCtrl.updateValueAndValidity();
    this.courseValidation = true;
    this.collegeYearValidation = true;
    this.semesterValidation = true;
    this.monthValidation = true;
    this.yearValidation = true;
    this.rollNOValidation = true;
    this.monthYearValidation = true;
    var error=this.findInvalidControls(this.edu_form.controls);
    //console.log("error---->"+error);

    //console.log("this.edu_form.valid---->"+this.edu_form.valid);
    if(this.user_id != null){
      if (this.edu_form.valid==false){
        this.edu_form.controls.collegeNameCtrl.markAsDirty();
        this.edu_form.controls.collegeAddressCtrl.markAsDirty();
        this.edu_form.controls.courseCtrl.markAsDirty();
        //this.edu_form.controls.overAllGradeCtrl.markAsDirty();
        this.edu_form.controls.rollNoCtrl.markAsDirty();
        this.edu_form.controls.partNameCtrl.markAsDirty();
  
        if(this.edu_form.controls.courseCtrl.value === null || this.edu_form.controls.courseCtrl.value ==='' || this.edu_form.controls.courseCtrl.value ===undefined){
          this.courseValidation = false;
        }else {
          this.courseValidation = true;
        }
  
        // if(this.edu_form.controls.semesterCtrl.value === null || this.edu_form.controls.semesterCtrl.value ==='' || this.edu_form.controls.semesterCtrl.value ===undefined){
        //   this.semesterValidation = false;
        // }else {
        //   this.semesterValidation = true;
        // }

        if(this.edu_form.controls.monthYearCtrl.value === null || this.edu_form.controls.monthYearCtrl.value ==='' || this.edu_form.controls.monthYearCtrl.value ===undefined){
          this.monthYearValidation = false;
        }else {
          this.monthYearValidation = true;
        }
  
        // if(this.edu_form.controls.monthCtrl.value === null || this.edu_form.controls.monthCtrl.value ==='' || this.edu_form.controls.monthCtrl.value ===undefined){
        //   this.monthValidation = false;
        // }else {
        //   this.monthValidation = true;
        // }
  
        // if(this.edu_form.controls.yearCtrl.value === null || this.edu_form.controls.yearCtrl.value ==='' || this.edu_form.controls.yearCtrl.value ===undefined){
        //   this.yearValidation = false;
        // }else {
        //   this.yearValidation = true;
        // }

        if(this.edu_form.controls.rollNoCtrl.invalid && (this.edu_form.controls.rollNoCtrl.dirty || this.edu_form.controls.rollNoCtrl.touched)){
          this.rollNOValidation = false;
        }else{
          this.rollNOValidation = true;
        }

      
  
      }else{
        var edu_data ={
          collegeName : this.edu_form.controls.collegeNameCtrl.value,
          collegeAddress : this.edu_form.controls.collegeAddressCtrl.value,
          courseName : this.edu_form.controls.courseCtrl.value,
          collegeYear : this.academicYear ? this.academicYear : this.pattern_year,
          semester: this.pattern_semester,
          month : this.edu_form.controls.monthYearCtrl.value,
          rollNo : this.edu_form.controls.rollNoCtrl.value,
          user_id : this.user_id,
          id : this.edu_id,
          pattern : this.pattern,
          duration : this.duration,
          degree_type : this.degree_type
        }
    
        this.api.setEducationalValues(edu_data)
        .subscribe(
          (data: any) => {  
            this.ref.close(data);
            err => console.error(err) 
        });
      }
    }else{
      alert("There is problem in saving data please login with your credentials and add further details.")
    }
  }

  dismiss() {
    this.ref.close();
  }

  selectCollegeBachelor(event, college , value, name, college_address){
    if (event.isUserInput) { 
      this.myControl.setValidators([ ]);
      this.myControl.updateValueAndValidity();
      this.edu_form.patchValue({
        collegeNameCtrl :name.toUpperCase(),
      })
      this.edu_form.patchValue({
        collegeAddressCtrl :college_address.toUpperCase(),
      })

      this.myControlCourse.reset();
      this.myControlCourse.updateValueAndValidity();
      this.coursenameshow = false;
      this.edu_form.patchValue({
        courseCtrl :'',
      })


      this.getCourseslist(name);
    }
  }

  getCourseslist(name){
    this.course_data = [];
    this.api.getCourses(this.course,name)
    .subscribe(
      (data: any) => { 
        if(data['status'] == 200){
          this.course_data = [];
          data['data'].forEach((element)=>{
            this.course_data.push( element ) 
          })
        }else{

        }
    });
  }

  selectCourse(event, college , value, name, part_name){console.log("inside select cprse",event,name,part_name);
  
    if (event.isUserInput) { 
      this.myControlCourse.setValidators([ ]);
      this.myControlCourse.updateValueAndValidity();
      this.pattern_data_sem = ['Sem I', 'Sem II', 'Sem III', 'Sem IV', 'Sem V', 'Sem VI', 'Sem VII', 'Sem VIII', 'Sem IX', 'Sem X', 'Sem XI', 'Sem XII'];
      this.pattern_data_year = ['First Year', 'Second Year', 'Third Year', 'Fourth Year', 'Fifth Year','Sixth Year'];
      this.duration = part_name;
      var duration_year = this.duration;
      var duration_sem = 2*this.duration;
      this.pattern_data_year = this.pattern_data_year.splice(0,duration_year);
      this.pattern_data_sem = this.pattern_data_sem.splice(0,duration_sem);
      // this.degree_type = term_name ? term_name : null;console.log("this.degree_type",this.degree_type);       
      this.edu_form.patchValue({
        courseCtrl : name.toUpperCase(),
      })
      //console.log(event.source.value);

    }
  }

  public findInvalidControls(controls) {
    const invalid = [];

    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
  }

  // MatAutocompleteTrigger 
  editCollegeName(){
    this.edu_form.controls.collegeNameCtrl.setValidators([Validators.required]);
    this.edu_form.controls.collegeNameCtrl.updateValueAndValidity();
    this.collegenameshow = false;
    this.edu_form.patchValue({
      collegeNameCtrl :'',
    })
    this.edu_form.patchValue({
      collegeAddressCtrl :'',
    })
    this.editCourseName();
  }

  editCourseName(){
    this.course_data = [];
    if(this.edu_data){
      this.getCourseslist(this.edu_data.CollegeName);
    }
    this.edu_form.controls.courseCtrl.setValidators([Validators.required]);
    this.edu_form.controls.courseCtrl.updateValueAndValidity();
    this.edu_form.controls.collegePatternCtrl.setValidators([Validators.required]);
    this.edu_form.controls.collegePatternCtrl.updateValueAndValidity();
    this.coursenameshow = false;
    this.edu_form.patchValue({
      courseCtrl :'',
    })
    this.edu_form.patchValue({
      collegePatternCtrl :'',
    })
    this.edu_form.patchValue({
      collegeSemPatternCtrl :'',
    })
    this.edu_form.patchValue({
      collegeYearPatternCtrl :'',
    })
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(event, datepicker: MatDatepicker<Moment>) {
  
    this.date.setValue(event);
    datepicker.close();
    this.edu_form.patchValue({
      monthYearCtrl :this.date.value,
    })
  }

  onFocus(value){
    this.autoComplete['_results'][value]._onChange('');
    this.autoComplete['_results'][value].openPanel();
    }

    OnlyNoCharAllowed(event: KeyboardEvent) {
      const inputElement = event.target as HTMLInputElement;
      const inputValue = inputElement.value;
      const pattern = /^[A-Za-z0-9]+$/; // Regular expression pattern for alphanumeric characters
    
      // Check if the input value matches the pattern
      if (!pattern.test(inputValue)) {
        // If the input doesn't match the criteria, prevent the input
        event.preventDefault();
      }
    
      // Check the length of the input and prevent input when it exceeds 20 characters
      if (inputValue.length > 20) {
        event.preventDefault();
        inputElement.value = inputValue.substring(0, 20); // Truncate to 20 characters
      }
    }
    

      getData(pattern){
        this.pattern = pattern.source.value;
        if(pattern.value == 'semester'){
          // this.pattern_data_sem = this.patternData.term_name;
          this.edu_form.controls.collegeSemPatternCtrl.setValidators([Validators.required]);
          this.edu_form.controls.collegeSemPatternCtrl.updateValueAndValidity();
          this.edu_form.controls.collegeYearPatternCtrl.clearValidators();
          this.edu_form.controls.collegeYearPatternCtrl.updateValueAndValidity();
          this.pattern_annual = true;
        }else if(pattern.value == 'annual'){
          // this.pattern_data_year = this.patternData.year;       
          this.edu_form.controls.collegeSemPatternCtrl.clearValidators(); 
          this.edu_form.controls.collegeSemPatternCtrl.updateValueAndValidity();     
          this.edu_form.controls.collegeYearPatternCtrl.setValidators([Validators.required]);
          this.edu_form.controls.collegeYearPatternCtrl.updateValueAndValidity();
          this.pattern_annual = false;
          this.pattern_semester = null;
        }//console.log("sem==",this.pattern_data_sem,"year== ",this.pattern_data_year);
        
      }

      onSelectPattern_year(year){
        this.pattern_year = year.value;
      }

      onSelectPattern_sem(pattern_sem){
        this.pattern_semester = pattern_sem.value;
        if(this.pattern_semester == 'Sem I' || this.pattern_semester == 'Sem II' ){
          this.academicYear = 'First Year';
        }else if(this.pattern_semester == 'Sem III' || this.pattern_semester == 'Sem IV'){
          this.academicYear = 'Second Year';
        }else if(this.pattern_semester == 'Sem V' || this.pattern_semester == 'Sem VI'){
          this.academicYear = 'Third Year';
        }else if(this.pattern_semester == 'Sem VII' || this.pattern_semester == 'Sem VIII'){
          this.academicYear = 'Fourth Year';
        }else if(this.pattern_semester == 'Sem IX' || this.pattern_semester == 'Sem X'){
          this.academicYear = 'Fifth Year';
        }else if(this.pattern_semester == 'Sem XI' || this.pattern_semester == 'Sem XII'){
          this.academicYear = 'Sixth Year';
        }
      }
      
}
